import classNames from "classnames";

type DividerProps = {
	customClassNames?: string;
	color?: string;
}

const getColorClassnamesFromName = (color: string | undefined) => {
	if (color) {
		return `border-${color} text-${color}`
	}

	return "border-gray-200 text-gray-200"
}

function Divider(
	{ customClassNames, color }: DividerProps
) {
	return (
		<div className={customClassNames}>
			<div className={classNames("border-[1px]", getColorClassnamesFromName(color))} />
		</div>
	);
}

export default Divider;
