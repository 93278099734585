const Colors = {
    MAX_500: "#2EDEE5",
    TROPICANA_500: "#FCB718",
    BLAZE_500: "#FE2962",
    SHADOW_500: "#3C4C66",
    MAX_700: "#26B5BB",
    TROPICANA_700: "#CD9514",
    BLAZE_700: "#CF2150",
    SHADOW_400: "#8A8E94",
    MAX_300: "#98EAEE",
    TROPICANA_300: "#FDD295",
    MAX_900: "#1B8084",
    TROPICANA_900: "#926A0E",
    BLAZE_900: "#931839",
    MAX_100: "#D2F4F7",
    TROPICANA_100: "#FEE9D1",
    BLAZE_100: "#FFD1D8",
    SHADOW_100: "#D3D5D8",
};

const DashboardColors = [
    "max-500",
    "tropicana-500",
    "blaze-500",
    "shadow-500",
    "max-700",
    "tropicana-700",
    "blaze-700",
    "shadow-400",
    "max-300",
    "tropicana-300",
    "blaze-300",
    "shadow-200",
    "max-900",
    "tropicana-900",
    "blaze-900",
    "max-100",
    "tropicana-100",
    "blaze-100",
]

export const getModularColor = (i: number) => {

    const idx = i % DashboardColors.length;
    return DashboardColors[idx]
}


export const dashboardColorToHex = (color: string): string => {
    const name = color.split("-")[0];
    const shade = color.split("-")[1];
    const c = Colors[`${name.toUpperCase()}_${shade}` as keyof typeof Colors];

    if (!c) {
        return Colors.MAX_500;
    }

    return c;
};