import { createContext, useState, useEffect, useContext, useCallback, useRef } from "react";
import { useSession } from "@supabase/auth-helpers-react";
import { useNetworkStatus } from "../../common/hooks/useNetworkStatus";
import { fetchPatientRecordsAsync, selectTableRecordStatus, setDate } from "../redux/patientTableRecordsSlice";
import { fetchDoctors } from "@redux/doctorSlice";
import { fetchAllDocuments } from "@redux/documentSlice";
import { getCreatorSectionNames } from "@/common/lib/supabaseClient";
import { useAccountContext } from "./AccountProvider";
import { UAParser } from "ua-parser-js";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import { useAppDispatch, useAppSelector } from "@/common/hooks/useRedux";

const supportedBrowsers = {
	Chrome: 100, // Unknown here.
	Edge: 102, // Unknown here: Released May 2022
	Safari: 14, // Known constraint with Media Recorder
	Firefox: 100, //Unknown here: Released May 2022
};
type BrowserName = keyof typeof supportedBrowsers;

export const DataContextApi = createContext<{
	localAudioDb: React.MutableRefObject<any>;
	selectedDate: {
		startDate: Date | null;
		endDate: Date | null;
	};
	setSelectedDate: React.Dispatch<
		React.SetStateAction<{
			startDate: Date | null;
			endDate: Date | null;
		}>
	>;
	isLoadingData: boolean;
	setIsLoadingData: React.Dispatch<React.SetStateAction<boolean>>;
	refreshPatientRecords: () => void;
	setSectionsAndFieldsToBold: React.Dispatch<React.SetStateAction<Set<string>>>;
	sectionsAndFieldsToBold: Set<string>;
}>({
	localAudioDb: { current: null },
	selectedDate: { startDate: new Date(), endDate: new Date() },
	setSelectedDate: () => { },
	isLoadingData: false,
	setIsLoadingData: () => { },
	refreshPatientRecords: () => { },
	setSectionsAndFieldsToBold: () => { },
	sectionsAndFieldsToBold: new Set<string>(),
});

/**
 * DataContextProvider is a component that provides the DataContextApi values to its child components.
 * It manages states such as localAudioDb, selectedDate, and isLoadingData, and handles the logic
 * for updating patient records and fetching data based on session and selected doctor.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components that will have access to the context.
 */
export const DataContextProvider = ({ children }: { children: React.ReactNode }) => {
	const session = useSession();
	useNetworkStatus();

	const dispatch = useAppDispatch();
	const { dataLoaded } = useAccountContext();

	const recordStatus = useAppSelector(selectTableRecordStatus);
	const { status } = useAppSelector((state) => state.documents);
	const doctorStatus = useAppSelector((state) => state.doctors.status);
	const selectedDoctor = useAppSelector((state) => state.doctors.selectedDoctor);
	const selectedDoctorRef = useRef(selectedDoctor);
	const [selectedDate, setSelectedDate] = useState({
		startDate: new Date(),
		endDate: new Date(),
	});
	const [sectionsAndFieldsToBold, setSectionsAndFieldsToBold] = useState(new Set<string>());
	const [isLoadingData, setIsLoadingData] = useState(dataLoaded ? false : true);
	const abortControllerRef = useRef<AbortController | null>(null);

	// update selected doctor ref and refetch patient records when selected doctor changes to avoid closure
	useEffect(() => {
		selectedDoctorRef.current = selectedDoctor;

		refreshPatientRecords()

	}, [selectedDoctor]);

	useEffect(() => {
		refreshPatientRecords()
	}, [selectedDate]);

	// Function to fetch data sequentially
	const fetchSequentially = async () => {
		// Abort any previous fetches
		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
		}

		// Create a new AbortController
		const controller = new AbortController();
		abortControllerRef.current = controller;

		try {
			if (session?.user?.id && dataLoaded) {
				setIsLoadingData(true);

				if (status !== "loading") {
					await dispatch(fetchAllDocuments({
						accountID: session?.user?.id,
						signal: controller?.signal
					}) as any);
				}

				if (doctorStatus !== "loading") {
					await dispatch(fetchDoctors(controller.signal) as any);
				}

				if (recordStatus !== "loading" && selectedDate?.startDate && selectedDate?.endDate) {
					let doctorId = selectedDoctorRef.current?.id || "";
					await dispatch(fetchPatientRecordsAsync({ selectedDate, doctorId, abortSignal: controller.signal }) as any);
				}

				const result = await getCreatorSectionNames(controller?.signal);
				if (result) {
					setSectionsAndFieldsToBold(new Set(result));
				}
			}
		} catch (error) {
			Sentry.captureException(error);
		} finally {
			setIsLoadingData(false);

			if (abortControllerRef.current === controller) {
				abortControllerRef.current = null;
			}

			controller.abort();
		}
	};

	useEffect(() => {
		if (dataLoaded) {
			fetchSequentially();
		}
	}, [session?.user?.id, dataLoaded, dispatch]);

	// Effect to update the date to the current time at midnight
	useEffect(() => {
		// Compute time to midnight on load
		const millisecondsUntilMidnight = () => {
			const now = new Date();
			const midnight = new Date(now);
			midnight.setHours(24, 0, 1, 0); // Set to one second past midnight

			return midnight.getTime() - now.getTime();
		};

		// Set a timeout for until midnight to update the date
		const timeoutId = setTimeout(() => {
			setSelectedDate({
				startDate: new Date(),
				endDate: new Date(),
			});
			dispatch(setDate(new Date().toISOString()));
		}, millisecondsUntilMidnight());

		return () => clearTimeout(timeoutId);
	}, [selectedDate]);

	// Effect to check the browser version on component mount and recommend an update if necessary
	useEffect(() => {
		try {
			const parser = new UAParser();
			const result = parser.getBrowser();
			const majorVersion = result?.major ? parseInt(result?.major, 10) : null;
			if (!majorVersion) return;

			// Using a type guard to ensure result.name is a key of supportedBrowsers
			if (typeof result?.name === "string" && result.name in supportedBrowsers) {
				const browserName = result.name as BrowserName; // Cast to BrowserName type
				if (majorVersion <= supportedBrowsers[browserName]) {
					toast.error(
						"Uh oh! This browser may not be entirely supported by HappyDoc. For best experience, we recommend upgrading to the latest version.",
						{
							autoClose: false,
							position: "top-right",
						},
					);
				}
			}
		} catch (error) {
			Sentry.captureException(error);
		}
	}, []);

	// Function to refresh patient records manually.
	const refreshPatientRecords = useCallback(async () => {
		const controller = new AbortController();

		if (recordStatus !== "loading") {
			let doctorId = selectedDoctorRef.current?.id || "";
			dispatch(fetchPatientRecordsAsync({ selectedDate, doctorId, abortSignal: controller?.signal }) as any);
		}

		return () => {
			controller.abort();
		};
	}, [selectedDate, recordStatus, selectedDoctorRef, dispatch]);

	const value = {
		selectedDate,
		setSelectedDate,
		isLoadingData,
		setIsLoadingData,
		refreshPatientRecords,
		sectionsAndFieldsToBold,
		setSectionsAndFieldsToBold,
	};

	return <DataContextApi.Provider value={value as any}>{children}</DataContextApi.Provider>;
};

export const useDataContextApi = () => {
	const context = useContext(DataContextApi);
	if (context === undefined) {
		throw new Error(`DataContextAPi must be used within a DataContextProvider`);
	}
	return context;
};
