type HDPercentageWheelProps = {
    percentage: number;
    height: number;
    width: number;
};

export const HDPercentageWheel = (props: HDPercentageWheelProps) => {
    const { percentage, height, width } = props;

    // ensure percentage is between 0 and 100
    let displayPercentage = 0;
    if (percentage > 0 && percentage <= 100) {
        displayPercentage = Math.round(percentage);
    } else if (percentage > 100) {
        displayPercentage = 100;
    }

    // calculate the stroke offset for the circular progress
    const radius = 28;
    const strokeWidth = 12;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (displayPercentage / 100) * circumference;

    return (
        <div className="inline-flex justify-center items-center relative" style={{ width: `${width}px`, height: `${height}px` }}>
            {/* circular progress */}
            <svg width="70" height="70" viewBox="0 0 70 70" className="transform rotate-[-90deg]">
                <circle cx="35" cy="35" r={radius} stroke="#EAECF0" strokeWidth={strokeWidth} fill="none" />
                <circle
                    cx="35"
                    cy="35"
                    r={radius}
                    stroke="#30B2BB"
                    strokeWidth={strokeWidth}
                    fill="none"
                    strokeDasharray={circumference}
                    strokeDashoffset={offset}
                    className="transition-all duration-500 ease-in-out"
                    strokeLinecap="round"
                />
            </svg>

            {/* text in the center of the circle */}
            <div className="absolute flex items-center justify-center text-gray-900 font-['Fraunces'] text-[14px]">
                <span>{`${displayPercentage}%`}</span>
            </div>
        </div>
    );
};
