// third-party libraries
import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { Tooltip } from 'react-tooltip';

// context and state management
import { useDashboardContext, getDataKey } from "@features/dashboard/state/DashboardProvider";

// common components and icons
import Spinner from "@common/components/Spinner";
import Divider from "@/common/components/Divider";
import StatDisplay, { LabelAlignment } from "@/common/components/StatDisplay";
import InfoCircleIcon from "@icons/info-circle.svg?react";

// dashboard-specific components and utilities
import HDProportionalBars, { BarData } from "@features/dashboard/components/common/HDProportionalBars";
import { getStartRangeTimestamp, getEndRangeTimestamp } from "@features/dashboard/components/common/DateRangeSelect";
import { DashboardDoctor, getLabelFromDoctorId, UNASSIGNED_DOCTOR_ID } from "@features/dashboard/components/common/DoctorMultiSelect";
import { MetricKeys } from "@features/dashboard/components/metrics/MetricComponent";

type AverageClientTransactionData = {
    averageTransactionAmount: number;
    breakdownByDoctor: {
        [doctorId: string]: number;

    };
};

type AverageClientTransactionDataset = {
    [dataKey: string]: AverageClientTransactionData;
}

export async function hydrateAverageClientTransaction(
    bearerToken: string,
    dashboardDoctors: DashboardDoctor[],
    startRangeTimestamp: string,
    endRangeTimestamp: string
): Promise<AverageClientTransactionData> {


    function seededRandom(seed: number): () => number {
        let value = seed;
        return function () {
            value = (value * 16807) % 2147483647;
            let toReturn = (value - 1) / 2147483646;
            if (toReturn < 0.1) {
                toReturn += 0.1;
            }
            return toReturn;
        };
    }

    let doctorIds = dashboardDoctors.map((doctor) => doctor.id);
    doctorIds = [...doctorIds, UNASSIGNED_DOCTOR_ID]

    // pick average based on date range
    let averageValue = 340;

    // change average value based on date range
    const startDate = new Date(startRangeTimestamp);
    const today = new Date();
    const daysDifference = Math.floor((today.getTime() - startDate.getTime()) / (1000 * 3600 * 24));

    if (daysDifference > 7 && daysDifference <= 30) {
        const reduction = 0.1;
        const reductionAmount = averageValue * reduction;
        averageValue -= reductionAmount;
    } else if (daysDifference > 30) {
        const addition = 0.2;
        const additionAmount = averageValue * addition;
        averageValue += additionAmount;
    }

    const averageTotal = averageValue * doctorIds.length;

    // seeded random number generator
    const randomGenerator = seededRandom(42);

    // generate random values and adjust them to match the total
    let randomValues = doctorIds.map(() => randomGenerator());
    let randomTotal = randomValues.reduce((sum, value) => sum + value, 0);

    // scale the random values to ensure they sum to the desired total
    randomValues = randomValues.map((value) => value * (averageTotal / randomTotal));

    // assign values to doctors
    let breakdownByDoctor = doctorIds.reduce((acc: { [key: string]: number }, doctorId, idx) => {
        acc[doctorId] = randomValues[idx];
        return acc;
    }, {});

    const calculatedAverage = Object.values(breakdownByDoctor).reduce((sum, value) => sum + value, 0) / doctorIds.length;

    return {
        averageTransactionAmount: calculatedAverage,
        breakdownByDoctor: breakdownByDoctor,
    };
}

const formatDollarAmount = (amount: number) => {
    return `$${Math.round(amount)}`;
}

export const AverageClientTransaction = () => {

    const { dashboardMetricsContext, dashboardStateContext } = useDashboardContext();
    const metricData = dashboardMetricsContext?.metricData[MetricKeys.AverageClientTransaction]?.data as AverageClientTransactionDataset;

    const { selectedDateRangeButtonValue, selectedDoctorIds, dashboardDoctors } = dashboardStateContext;

    // state for managing data
    const [componentData, setComponentData] = useState<AverageClientTransactionData | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [selectedAmount, setSelectedAmount] = useState<number>(0);

    // state to track if all doctors are selected
    const [allDocsSelected, setAllDocsSelected] = useState<boolean>(false);

    // update allDocsSelected when selectedDoctorIds change
    useEffect(() => {
        setAllDocsSelected(selectedDoctorIds.length === dashboardDoctors.length);
    }, [selectedDoctorIds, dashboardDoctors]);

    // update componentData when metricData or selectedDateRangeButtonValue change
    useEffect(() => {
        if (!metricData || !selectedDateRangeButtonValue) return;

        const data = metricData[getDataKey(dashboardDoctors, getStartRangeTimestamp(selectedDateRangeButtonValue), getEndRangeTimestamp(selectedDateRangeButtonValue))];
        setComponentData(data);
        setLoading(false);

    }, [dashboardDoctors, metricData, selectedDateRangeButtonValue]);

    // update total amount when component data changes
    useEffect(() => {
        if (!componentData) return;

        const total = componentData?.averageTransactionAmount;
        setTotalAmount(total);
    }, [componentData]);

    // update selected amount when component data or selected doctor ids change
    useEffect(() => {
        if (!componentData) return;

        // include unassigned doctor if all doctors are selected
        let baseIds = selectedDoctorIds;
        if (allDocsSelected) {
            baseIds = [...baseIds, UNASSIGNED_DOCTOR_ID];
        }

        // calculate selected total
        const selectedTotal = baseIds.reduce((sum, doctorId) => sum + (componentData.breakdownByDoctor[doctorId] || 0), 0);

        // calculate selected average
        const selectedAvg = selectedTotal / (baseIds.length);

        // update state
        setSelectedAmount(selectedAvg);

    }, [componentData, selectedDoctorIds, allDocsSelected]);

    return (
        <div className="w-full h-auto min-h-[181px] bg-white rounded-md border border-gray-300">
            {loading || !componentData ? (
                <div className="w-full h-full min-h-[284px] flex items-center justify-center">
                    <Spinner />
                </div>
            ) : (
                <div className="px-4">
                    {/* HEADER */}
                    <div className="flex items-center py-4 justify-between">
                        <div className="flex items-center gap-2">
                            <div className="text-lg font-semibold leading-7">Average Client Transaction</div>
                            <div>
                                <InfoCircleIcon
                                    className="w-[16px] h-[16px] text-gray-400"
                                    data-tooltip-id="tooltip-avg-transaction"
                                />
                                <Tooltip
                                    style={{
                                        maxWidth: "200px",
                                        zIndex: 9999,
                                        fontSize: "12px",
                                        fontFamily: "Plus Jakarta Sans",
                                        textAlign: "center",
                                    }}
                                    anchorSelect="[data-tooltip-id='tooltip-avg-transaction']"
                                    content={
                                        "Average dollar amount spent by clients per visit. Sync'd via PIMS integration."
                                    }
                                    place="top"
                                />
                            </div>

                        </div>
                        <StatDisplay
                            value={formatDollarAmount(totalAmount)}
                            label={allDocsSelected ? "TOTAL" : "PRACTICE TOTAL"}
                            backgroundColor="bg-max-50"
                            width="w-auto"
                            labelAlignment={LabelAlignment.Row}
                        />
                    </div>
                    <Divider customClassNames="rounded-lg" color="gray-100" />
                    {/* TOTAL FOR SELECTED */}
                    <div className="py-5">
                        <div className="flex flex-row gap-2 py-2">
                            <div className="flex items-center text-5xl font-semibold font-['Fraunces']">{formatDollarAmount(selectedAmount)}</div>
                            <div className="flex items-end justify-end text-sm text-gray-600 pb-1">Per Month</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}