import { Route } from "react-router-dom";
import PermissionWrapper from "./PermissionWrapper";
import AsyncComponent from "./AsyncComponent";
import {
	APPOINTMENTS_ROUTE,
	ENDPOINTS,
	INDIVDUAL_DOCUMENT_TYPE_ROUTE_ADVANCED,
	PAYMENT_CONFIRMATION_ROUTE,
} from "../common/utils/url-registry";
import NotFoundPage from "../common/components/NotFound";
import { DashboardProvider } from "@/features/dashboard/state/DashboardProvider";

function AuthenticatedRoutes() {
	return (
		<>
			<Route
				index
				path={ENDPOINTS.Dashboard}
				element={
					<PermissionWrapper>
						<DashboardProvider>
							<AsyncComponent loadComponent={() => import("@/features/dashboard/Dashboard")} />
						</DashboardProvider>
					</PermissionWrapper>
				}
			/>
			<Route
				index
				path="/"
				element={
					<PermissionWrapper>
						<AsyncComponent loadComponent={() => import("@features/visits/Visit")} />
					</PermissionWrapper>
				}
			/>
			<Route index path={ENDPOINTS.SignUp} element={<AsyncComponent loadComponent={() => import("@common/components/CheckEmail")} />} />
			<Route
				path={PAYMENT_CONFIRMATION_ROUTE}
				element={
					<PermissionWrapper>
						<AsyncComponent loadComponent={() => import("@features/payment/PaymentConfirmation")} />
					</PermissionWrapper>
				}
			/>
			<Route path={ENDPOINTS.Payment} element={<AsyncComponent loadComponent={() => import("@/features/payment/Payment")} />} />
			<Route
				path={ENDPOINTS.UpdatePassword}
				element={
					<PermissionWrapper>
						<AsyncComponent loadComponent={() => import("@features/auth/UpdatePassword")} />
					</PermissionWrapper>
				}
			/>
			<Route
				path={ENDPOINTS.Calendar}
				element={
					<PermissionWrapper>
						<AsyncComponent loadComponent={() => import("@common/components/NotFound")} />
					</PermissionWrapper>
				}
			/>
			<Route
				path={APPOINTMENTS_ROUTE}
				element={
					<PermissionWrapper>
						<AsyncComponent loadComponent={() => import("@features/patient-view/PatientView")} />
					</PermissionWrapper>
				}
			/>
			<Route
				path={ENDPOINTS.DocumentBuilder}
				element={
					<PermissionWrapper>
						<AsyncComponent
							loadComponent={() => import("../features/document-types/document-builder/BuilderPage")}
						/>
					</PermissionWrapper>
				}
			/>
			<Route
				path={INDIVDUAL_DOCUMENT_TYPE_ROUTE_ADVANCED}
				element={
					<PermissionWrapper>
						<AsyncComponent
							loadComponent={() => import("../features/document-types/advanced-builder/IndividualDocType")}
						/>
					</PermissionWrapper>
				}
			/>
			<Route
				path={ENDPOINTS.Settings}
				element={
					<PermissionWrapper>
						<AsyncComponent loadComponent={() => import("../features/settings/Settings")} />
					</PermissionWrapper>
				}
			/>
			<Route path="*" element={<NotFoundPage />} />
		</>
	);
}

export default AuthenticatedRoutes;
