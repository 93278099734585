// third-party libraries
import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { Tooltip } from 'react-tooltip';

// context and state management
import { useDashboardContext, getDataKey } from "@features/dashboard/state/DashboardProvider";

// common components and icons
import Spinner from "@common/components/Spinner";
import Divider from "@/common/components/Divider";
import StatDisplay, { LabelAlignment } from "@/common/components/StatDisplay";
import InfoCircleIcon from "@icons/info-circle.svg?react";

// dashboard-specific components and utilities
import HDProportionalBars, { BarData } from "@features/dashboard/components/common/HDProportionalBars";
import { getStartRangeTimestamp, getEndRangeTimestamp } from "@features/dashboard/components/common/DateRangeSelect";
import { DashboardDoctor, getLabelFromDoctorId, UNASSIGNED_DOCTOR_ID } from "@features/dashboard/components/common/DoctorMultiSelect";
import { MetricKeys } from "@features/dashboard/components/metrics/MetricComponent";

// API and queries
import { makeServiceInsightsGraphqlRequest, RECORDS_GENERATED_QUERY } from "../dashboardAPI";

type RecordsGeneratedData = {
    recordsGenerated: number;
    breakdownByDoctor: {
        [doctorId: string]: number;
    }
}

type RecordsGeneratedDataset = {
    [key: string]: RecordsGeneratedData;
}

export async function hydrateRecordsGenerated(
    bearerToken: string,
    dashboardDoctors: DashboardDoctor[],
    startRangeTimestamp: string,
    endRangeTimestamp: string
): Promise<Record<string, any>> {

    const variables = {
        startRangeTimestamp,
        endRangeTimestamp,
        selectedDoctorIDs: [...dashboardDoctors.map((doc) => doc.id), UNASSIGNED_DOCTOR_ID], // retrieve data for all doctors in the dashboard, and unassigned
    }

    try {
        const { data, error } = await makeServiceInsightsGraphqlRequest(RECORDS_GENERATED_QUERY, variables, bearerToken);

        if (error) {
            throw error
        }

        // transform the result into your desired structure
        const breakdownByDoctor = data?.recordsGenerated?.breakdownByDoctor.reduce(
            (acc: Record<string, number>, doctor: { doctorId: string; documentCount: number }) => {
                acc[doctor.doctorId] = doctor.documentCount;
                return acc;
            },
            {}
        );

        return {
            recordsGenerated: data?.recordsGenerated?.totalRecordsGenerated,
            breakdownByDoctor,
        };
    } catch (error) {
        Sentry.captureException(`Error fetching recordsGenerated: ${error}`);
        return {
            recordsGenerated: 0,
            breakdownByDoctor: {},

        }
    }
}

/*
* RecordsGenerated is displays the amount of records generated for selected doctors.
*/
export const RecordsGenerated = () => {

    const { dashboardMetricsContext, dashboardStateContext } = useDashboardContext();
    const metricData = dashboardMetricsContext?.metricData[MetricKeys.RecordsGenerated]?.data as RecordsGeneratedDataset;

    const { selectedDateRangeButtonValue, selectedDoctorIds, dashboardDoctors } = dashboardStateContext;

    // state for managing what is currently displayed in the graph
    const [componentData, setComponentData] = useState<RecordsGeneratedData | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const [barData, setBarData] = useState<BarData[]>([]);
    const [remainderData, setRemainderData] = useState<BarData | undefined>(undefined);

    // state to track if all doctors are selected
    const [allDocsSelected, setAllDocsSelected] = useState<boolean>(false);

    // update allDocsSelected when selectedDoctorIds change
    useEffect(() => {
        setAllDocsSelected(selectedDoctorIds.length === dashboardDoctors.length);
    }, [selectedDoctorIds, dashboardDoctors]);


    // update componentData when metricData or selectedDateRangeButtonValue change
    useEffect(() => {
        if (!metricData || !selectedDateRangeButtonValue) return;

        const data = metricData[getDataKey(dashboardDoctors, getStartRangeTimestamp(selectedDateRangeButtonValue), getEndRangeTimestamp(selectedDateRangeButtonValue))];
        setComponentData(data);
        setLoading(false);

    }, [dashboardDoctors, metricData, selectedDateRangeButtonValue]);

    // update displayed data when componentData changes
    useEffect(() => {
        // if all doctors are selected, display all doctors in the graph
        if (selectedDoctorIds.length === dashboardDoctors.length) {

            // if all doctors are selected, display all doctors in the graph
            const barData = Object.entries(componentData?.breakdownByDoctor || {}).map(([doctorId, count]) => {
                return {
                    numeric: count,
                    label: getLabelFromDoctorId(doctorId, dashboardDoctors),
                    detail: `${count} Generated`
                }
            });

            // set the bar data
            setBarData(barData);

            // compute remainder (from deleted doctors) as difference between total records generated and sum of displayed doctors
            const totalBarCount = Object.values(barData || {}).reduce((sum, data) => sum + data?.numeric, 0);
            const totalPracticeCount = componentData?.recordsGenerated || 0;
            const remainderCount = totalPracticeCount ? totalPracticeCount - totalBarCount : 0
            const remainderData = {
                numeric: remainderCount,
                detail: `${remainderCount} Generated`,
                label: "Other Doctors",
                hideLabel: true
            }

            // set the remainder data
            setRemainderData(remainderData);

        } else {
            // otherwise, display only the selected doctors
            const barData = selectedDoctorIds.map((doctorId) => {
                const count = componentData?.breakdownByDoctor[doctorId] || 0;
                return {
                    numeric: count,
                    label: getLabelFromDoctorId(doctorId, dashboardDoctors),
                    detail: `${count} Generated`
                }
            });

            // set the bar data
            setBarData(barData);

            // compute remainder as difference between total records generated and sum of displayed doctors
            const totalSelectedCount = Object.values(barData || {}).reduce((sum, data) => sum + data?.numeric, 0);
            const totalPracticeCount = componentData?.recordsGenerated || 0;
            const remainderCount = totalPracticeCount ? totalPracticeCount - totalSelectedCount : 0
            const remainderData = {
                numeric: remainderCount,
                detail: `${remainderCount} Generated`,
                label: "Other Doctors",
                hideLabel: true
            }

            // set the remainder data
            setRemainderData(remainderData);

        }
    }, [componentData, selectedDoctorIds, dashboardDoctors]);

    return (
        <div className="w-full h-auto min-h-[284px] bg-white rounded-md border border-gray-300">
            {loading || !componentData ? (
                <div className="w-full h-full min-h-[284px] flex items-center justify-center">
                    <Spinner />
                </div>
            ) : (
                <div className="px-4">
                    {/* HEADER */}
                    <div className="flex items-center py-4 justify-between">
                        <div className="flex items-center gap-2">
                            <div className="text-lg font-semibold leading-7">Records Generated</div>
                            <div>
                                <InfoCircleIcon
                                    className="w-[16px] h-[16px] text-gray-400"
                                    data-tooltip-id="tooltip-records-generated"
                                />
                                <Tooltip
                                    style={{
                                        maxWidth: "200px",
                                        zIndex: 9999,
                                        fontSize: "12px",
                                        fontFamily: "Plus Jakarta Sans",
                                        textAlign: "center",
                                    }}
                                    anchorSelect="[data-tooltip-id='tooltip-records-generated']"
                                    content={
                                        "A count of records generated in HappyDoc. Each unique document type per Patient is counted as a unique record."
                                    }
                                    place="top"
                                />
                            </div>

                        </div>
                        <StatDisplay
                            value={componentData.recordsGenerated.toString()}
                            label={allDocsSelected ? "TOTAL" : "PRACTICE TOTAL"}
                            backgroundColor="bg-max-50"
                            width="w-auto"
                            labelAlignment={LabelAlignment.Row}
                        />
                    </div>
                    <Divider customClassNames="rounded-lg" color="gray-100" />
                    {/* TOTAL FOR SELECTED */}
                    <div className="py-5">
                        <div className="flex flex-row gap-2 py-2">
                            <div className="flex items-center text-5xl font-semibold font-['Fraunces']">{Object.values(barData || {}).reduce((sum, data) => sum + data?.numeric, 0)}</div>
                            <div className="flex items-end justify-end text-sm text-gray-600 pb-1"> Total Medical Records</div>
                        </div>
                    </div>
                    {/* BREAKDOWN BY DOCTOR */}
                    <HDProportionalBars data={barData} remainderData={remainderData} />
                </div>
            )}
        </div>
    );
}
