// third party libraries
import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { Tooltip } from 'react-tooltip';
import { LineChart } from '@mui/x-charts';

// context and state management
import { useDashboardContext, getDataKey } from "@features/dashboard/state/DashboardProvider";

// common components and icons
import Spinner from "@common/components/Spinner";
import Divider from "@/common/components/Divider";
import StatDisplay, { LabelAlignment } from "@/common/components/StatDisplay";
import InfoCircleIcon from "@icons/info-circle.svg?react";

// dashboard components
import { getStartRangeTimestamp, getEndRangeTimestamp, DateRangeOptions } from "@features/dashboard/components/common/DateRangeSelect";
import { DashboardDoctor, getLabelFromDoctorId, UNASSIGNED_DOCTOR_ID } from "@features/dashboard/components/common/DoctorMultiSelect";
import { MetricKeys } from "@features/dashboard/components/metrics/MetricComponent";
import { getModularColor, dashboardColorToHex } from "@features/dashboard/components/common/color";

// API and queries
import { makeServiceInsightsGraphqlRequest, TIME_SAVED_QUERY } from "../dashboardAPI";

type TimeSavedData = {
    totalTimeSaved: number;
    totalNumberEffectivePatients: number;
    breakdownByDoctor: {
        doctorId: string;
        timeSaved: number;
        numberEffectivePatients: number;
        breakdownByDay: {
            [key: string]: {
                numberEffectivePatients: number;
                timeSaved: number;
            };
        };
    }[];
};

type TimeSavedDataset = {
    [key: string]: TimeSavedData;
}

export async function hydrateTimeSaved(bearerToken: string, dashboardDoctors: DashboardDoctor[], startRangeTimestamp: string, endRangeTimestamp: string): Promise<unknown> {

    // set variables for graphql query
    const variables = {
        startRangeTimestamp,
        endRangeTimestamp,
        selectedDoctorIDs: [...dashboardDoctors.map((doc) => doc.id), UNASSIGNED_DOCTOR_ID], // retrieve data for all doctors in the dashboard, and unassigned
    }

    try {
        // make graphql request
        const { data, error } = await makeServiceInsightsGraphqlRequest(TIME_SAVED_QUERY, variables, bearerToken);

        // handle error
        if (error) {
            throw error
        }

        // return data
        return data?.timeSaved

    } catch (error) {
        Sentry.captureException(`Error fetching time saved: ${error}`);
        return {
            totalTimeSaved: 0,
            totalNumberEffectivePatients: 0,
            breakdownByDoctor: {}
        }
    }
}

const getDaysFromDateSelection = (selection: string): string[] => {
    const days = (() => {
        switch (selection) {
            case DateRangeOptions.Days30:
                return 30;
            case DateRangeOptions.Days60:
                return 60;
            case DateRangeOptions.Days7:
                return 7;
            default:
                throw new Error("Invalid date range selection");
        }
    })();

    const dates: string[] = [];
    const today = new Date();

    for (let i = 0; i < days; i++) {
        const pastDate = new Date(today);
        pastDate.setDate(today.getDate() - i);
        dates.push(pastDate.toISOString().split('T')[0]); // Format as "YYYY-MM-DD"
    }

    return dates.reverse();
}

export const TimeSaved = () => {

    // CONTEXT AND DATA PROVIDERS
    // provided data
    const { dashboardMetricsContext, dashboardStateContext } = useDashboardContext();
    const metricData = dashboardMetricsContext?.metricData[MetricKeys.TimeSaved]?.data as TimeSavedDataset;
    const { selectedDateRangeButtonValue, selectedDoctorIds, dashboardDoctors } = dashboardStateContext;

    // component data and loading state
    const [componentData, setComponentData] = useState<TimeSavedData | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);

    // update componentData when metricData or selectedDateRangeButtonValue change
    useEffect(() => {
        if (!metricData || !selectedDateRangeButtonValue) return;

        const data = metricData[getDataKey(dashboardDoctors, getStartRangeTimestamp(selectedDateRangeButtonValue), getEndRangeTimestamp(selectedDateRangeButtonValue))];
        setComponentData(data);
        setLoading(false);

    }, [dashboardDoctors, metricData, selectedDateRangeButtonValue]);

    // SORTING DOCTOR IDS
    const [sortedSelectedDoctorIds, setSortedSelectedDoctorIds] = useState<string[]>([]);

    // update order of selectedDoctorIds when selectedDoctorIds change
    useEffect(() => {
        if (!componentData) {
            return;
        }

        // include unassigned doctor if all doctors are selected
        let baseIds = selectedDoctorIds;
        if (selectedDoctorIds.length === dashboardDoctors.length) {
            baseIds = [...baseIds, UNASSIGNED_DOCTOR_ID]
        }

        // filter out doctors with no time saved
        baseIds = baseIds.filter((doctorId) => {
            const timeSaved = componentData?.breakdownByDoctor?.find((doc) => doc.doctorId === doctorId)?.timeSaved || 0;
            return timeSaved > 0;
        });

        // sort doctor ids in decreasing order of time saved
        setSortedSelectedDoctorIds(baseIds.sort((a, b) => {
            const timeSavedA = componentData?.breakdownByDoctor?.find((doc) => doc.doctorId === a)?.timeSaved || 0;
            const timeSavedB = componentData?.breakdownByDoctor?.find((doc) => doc.doctorId === b)?.timeSaved || 0;

            // break ties alphabetically
            if (timeSavedA === timeSavedB) {
                return getLabelFromDoctorId(a, dashboardDoctors).localeCompare(getLabelFromDoctorId(b, dashboardDoctors));
            }

            return timeSavedB - timeSavedA;
        }));
    }, [selectedDoctorIds, componentData]);

    // TOTAL TIME SAVED
    // state to track total time saved
    const [totalTimeSaved, setTotalTimeSaved] = useState<number>(0);

    // update total time saved when componentData changes
    useEffect(() => {
        setTotalTimeSaved(componentData?.totalTimeSaved || 0);
    }, [componentData]);


    // DOCTOR SELECTION
    // state to track if all doctors are selected
    const [allDocsSelected, setAllDocsSelected] = useState<boolean>(false);

    // update allDocsSelected when selectedDoctorIds change
    useEffect(() => {
        setAllDocsSelected(selectedDoctorIds.length === dashboardDoctors.length);
    }, [selectedDoctorIds, dashboardDoctors]);

    // LINE CHART DATA
    // state to track line chart data
    const [lineChartXValues, setLineChartXValues] = useState<string[]>([]);
    const [lineChartData, setLineChartData] = useState<{ id: string, label: string, color: string, data: { x: string, y: number }[] }[]>([]);

    // update lineChartData when componentData changes
    useEffect(() => {
        // generate x valuers from date range selection
        const xValues = getDaysFromDateSelection(selectedDateRangeButtonValue)

        const sortedXValues = Array.from(xValues).sort();
        setLineChartXValues(sortedXValues);

        // create line chart data
        const lineChartData: { id: string, label: string; color: string, data: { x: string, y: number }[] }[] = []
        sortedSelectedDoctorIds.forEach((doctorId, idx) => {
            // filter out doctors that are not selected
            if (!sortedSelectedDoctorIds.includes(doctorId)) {
                return;
            }

            // retrieve doctor data
            const doctorData = componentData?.breakdownByDoctor?.find((doc) => doc.doctorId === doctorId);
            const breakdownByDay = doctorData?.breakdownByDay || {};

            // transform and add to lineChartData
            lineChartData.push({
                id: doctorId,
                label: getLabelFromDoctorId(doctorId, dashboardDoctors),
                color: dashboardColorToHex(getModularColor(idx)),
                data: sortedXValues.map((xValue) => ({
                    x: xValue, // Use normalized date string here
                    y: breakdownByDay[xValue]?.timeSaved / 60 || 0,
                }))
            });
        });

        // set the line chart data state
        setLineChartData(lineChartData);
    }, [selectedDateRangeButtonValue, componentData, sortedSelectedDoctorIds, dashboardDoctors]);

    /*
    * Parses a date string into a Date object in the local timezone.
    */
    const parseLocalDate = (dateStr: string): Date => {
        try {
            const [year, month, day, ...rest] = dateStr.split(/[-T:]/).map(Number);
            return new Date(year, month - 1, day, rest[0] || 0, rest[1] || 0, rest[2] || 0);
        } catch (error) {
            Sentry.captureException(`Error parsing date: ${dateStr}. ${JSON.stringify(error)}`);
            return new Date();
        }

    };

    const formatDateFromDateRange = (date: string, selectedDateRangeButtonValue: string): string => {
        switch (selectedDateRangeButtonValue) {
            case DateRangeOptions.Days60:
            case DateRangeOptions.Days30:
                return parseLocalDate(date).toDateString().split(" ").slice(1, -1).join(" ");
            case DateRangeOptions.Days7:
            default:
                return parseLocalDate(date).toDateString().split(" ")[0]
        }
    }

    const minsToHrs = (mins: number): string => {
        return (mins / 60).toFixed(1)
    }

    return (
        <div className="w-full h-auto min-h-[410px] bg-white rounded-md border border-gray-300">
            {loading || !componentData ? (
                <div className="w-full h-full min-h-[410px] flex items-center justify-center">
                    <Spinner />
                </div>
            ) : (
                <div className="px-4">
                    {/* HEADER */}
                    <div className="flex items-center py-4 justify-between">
                        <div className="flex items-center gap-2">
                            <div className="text-lg font-semibold leading-7">Time Saved</div>
                            <div>
                                <InfoCircleIcon
                                    className="w-[16px] h-[16px] text-gray-400"
                                    data-tooltip-id="tooltip-time-saved"
                                />
                                <Tooltip
                                    style={{
                                        maxWidth: "200px",
                                        zIndex: 9999,
                                        fontSize: "12px",
                                        fontFamily: "Plus Jakarta Sans",
                                        textAlign: "center",
                                    }}
                                    anchorSelect="[data-tooltip-id='tooltip-time-saved']"
                                    content={
                                        "Calculated by taking an avg of 6 mins saved per patient seen with HappyDoc."
                                    }
                                    place="top"
                                />
                            </div>
                        </div>
                        <StatDisplay
                            value={`${minsToHrs(totalTimeSaved)} hrs`}
                            label={allDocsSelected ? "TOTAL" : "PRACTICE TOTAL"}
                            backgroundColor="bg-max-50"
                            width="w-auto"
                            labelAlignment={LabelAlignment.Row}
                        />
                    </div>
                    <Divider customClassNames="rounded-lg" color="gray-100" />
                    {/* SCROLLABLE LEGEND */}
                    <div className="flex flex-row w-full h-auto min-h-[100px] gap-[30px] pt-5 overflow-x-auto whitespace-nowrap">
                        {
                            sortedSelectedDoctorIds.map((doctorId, idx) => {

                                // retrieve data
                                const doctorData = componentData?.breakdownByDoctor?.find((doc) => doc.doctorId === doctorId);
                                const numberEffectivePatients = doctorData?.numberEffectivePatients || 0;

                                return (
                                    <div data-tooltip-anchor={`tooltip-doctor-${doctorId}`} className="w-auto" key={`time-saved-legend-${idx}`}>
                                        <div className="flex flex-row items-center gap-2">
                                            <div className={`w-4 h-4 rounded-md bg-${getModularColor(idx)}`}>
                                            </div>
                                            <div className="text-sm font-semibold text-gray-600">
                                                {getLabelFromDoctorId(doctorId, dashboardDoctors)}
                                            </div>
                                            {/* Tooltip with the number of patients */}
                                            <Tooltip
                                                style={{
                                                    maxWidth: "150px",
                                                    fontSize: "12px",
                                                    fontFamily: "Plus Jakarta Sans",
                                                    textAlign: "center",
                                                }}
                                                anchorSelect={`[data-tooltip-anchor='tooltip-doctor-${doctorId}']`}
                                                content={numberEffectivePatients > 1 ? `${numberEffectivePatients} patients` : `${numberEffectivePatients} patient`}
                                                place="top"
                                            />
                                        </div>
                                        <div className="pb-6 text-3xl font-semibold font-['Fraunces'] text-gray-900">
                                            {
                                                minsToHrs(doctorData?.timeSaved || 0) + " hrs"
                                            }
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                    {/* LINE CHART */}
                    <div className="">
                        <LineChart
                            height={228}
                            xAxis={[{
                                scaleType: "time",
                                data: lineChartXValues.map((date) => new Date(date)),
                                valueFormatter: (date, context) => {
                                    if (context.location === "tick") {
                                        return formatDateFromDateRange(date.toISOString(), selectedDateRangeButtonValue)
                                    } else {
                                        return parseLocalDate(date.toISOString()).toDateString().split(" ").slice(0, -1).join(" ");

                                    }
                                },
                                tickInterval: lineChartXValues.map((date) => new Date(date)),
                                disableTicks: true,
                                disableLine: true,
                            }]}
                            yAxis={[{
                                disableTicks: true,
                                disableLine: true,
                                max: lineChartData.reduce((max, series) => Math.max(max, ...series.data.map(point => point.y)), 0) * 1.1,
                            }]}
                            series={lineChartData.map(series => ({
                                ...series,
                                data: series.data.map(point => point.y),
                                showMark: false,
                            }))}
                            slotProps={{
                                legend: { hidden: true }
                            }}
                            margin={{ left: 40, right: 20, top: 0, bottom: 40 }}
                            grid={{ horizontal: true }}
                            tooltip={{
                                trigger: "axis",
                            }}
                            axisHighlight={{
                                x: 'line',
                                y: 'none'
                            }}
                            // unfortunately, the theme provider library for MUI does not support x-charts
                            // todo: extend the ThemeProvider interface to support x-charts
                            sx={{
                                //change left yAxis label styles
                                "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                                    fill: "#475467"
                                },
                                // change bottom label styles
                                "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                                    fill: "#475467"
                                },
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

