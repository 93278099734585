import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import classNames from "classnames";
import moment from "moment";
import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { type PatientRecord, } from "../utils/types";
import { debounce } from "lodash";
import SearchIcon from "@icons/search-lg.svg?react";
import * as Sentry from "@sentry/react";
import XIcon from "@icons/x-close.svg?react";
import { search } from '../lib/api';

type Props = {
	verticalLayout?: boolean;
	onSelect?: (appointment: PatientRecord) => void;
	lastNDays?: number;
	notId?: string;
	isSearchBarVisible: boolean;
	toggleSearchBar: () => void;
	disabled?: boolean;
};

const USE_SUPABASE_SEARCH = process.env.VITE_USE_SUPABASE_SEARCH === 'true';

/**
 * SearchBar component allows users to search for patient records and select an appointment.
 * 
 * @component
 * @param {boolean} [verticalLayout=false] - Determines if the layout should be vertical.
 * @param {function} onSelect - Callback function to handle the selection of an appointment.
 * @param {number} lastNDays - Number of days to filter the search results.
 * @param {string} notId - ID to exclude from the search results.
 * @param {boolean} isSearchBarVisible - Flag to toggle the visibility of the search bar.
 * @param {function} toggleSearchBar - Function to toggle the search bar visibility.
 * @param {boolean} [disabled=false] - Flag to disable the search bar.
 * 
 * @returns {JSX.Element} The rendered SearchBar component.
 */
const SearchBar = ({ verticalLayout = false, onSelect, lastNDays, notId, isSearchBarVisible, toggleSearchBar, disabled = false }: Props) => {
	const supabase = useSupabaseClient();
	const [searchTerm, setSearchTerm] = useState("");
	const [fetchedData, setFetchedData] = useState<PatientRecord[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedAppointment, setSelectedAppointment] = useState<PatientRecord | null>(null);

	const navigate = useNavigate();

	/**
	 * Fetches search results from the 'patient_record' table in Supabase with debounce.
	 * 
	 * @param {string} field - The field to filter the search results by.
	 * @param {string} filter - The filter value to search for. Must be at least 3 characters long.
	 */
	const fetchResults = useCallback(
		debounce(async (field: string, filter: string) => {

			if (!filter || filter.length < 3) {
				setFetchedData([]);
				return;
			}

			setIsLoading(true);

			try {
				const query = supabase
					.from('patient_record')
					.select(`
							id,
							patient_name,
							patient_id,
							patient_id_new,
							scheduled_at,
							contact_id,
							contacts (
								id,
								first_name,
								last_name
							)
						`)
					.ilike(field, filter)
					.eq('is_archived', false)
					.order('scheduled_at', { ascending: false })
					.limit(10);

				if (notId) {
					query.neq('id', notId);
				}

				if (lastNDays) {
					query.gte('scheduled_at', moment().subtract(lastNDays, 'days').toISOString());
				}

				const { data, error } = await query;

				if (error) {
					throw new Error(`Error fetching search results: ${error.message}`);
				}

				if (data && data.length > 0) {
					setFetchedData(data as unknown as PatientRecord[]);
				} else {
					setFetchedData([]);
				}

			} catch (error) {
				console.error('Error fetching data:', error);
				Sentry.captureException(error);
				setFetchedData([]);
			} finally {
				setIsLoading(false);
			}
		},
			500), [supabase, notId, lastNDays]
	);

	/**
	 * Fetches search results with a debounce effect.
	 * 
	 * This function is wrapped in a `useCallback` hook to ensure it is only recreated
	 * when its dependencies change. It uses a debounce mechanism to limit the rate at which
	 * the search function is called, improving performance and reducing unnecessary API calls.
	 * 
	 * @param {string} input - The search input string.
	 * 
	 * @returns {void}
	 * 
	 * @remarks
	 * - If `disabled` is true or `input` is empty, the function will clear the fetched data and return early.
	 * - Sets the loading state to true before initiating the fetch and resets it to false in the `finally` block.
	 * - Captures and logs any errors encountered during the fetch process using Sentry.
	 * - Updates the fetched data state based on the search results.
	 */
	const fetchResultsV2 = useCallback(
		debounce(async (input: string) => {
			if (disabled || !input) {
				setFetchedData([]);
				return;
			}
			setIsLoading(true);

			try {
				let { data, error } = await search(input);

				if (error) {
					console.error('Error fetching data:', error);
					Sentry.captureException(error);
					setFetchedData([]);
					return;
				}

				if (data && data.length > 0) {
					setFetchedData(data);
					return;
				}

				//Nothing found:				
				setFetchedData([]);
			} catch (error) {
				console.error('Error fetching data:', error);
				Sentry.captureException(error);
				setFetchedData([]);
			} finally {
				setIsLoading(false);
			}
		}, 500), [supabase, disabled, notId, lastNDays]
	);

	useEffect(() => {
		if (searchTerm && !disabled) {
			USE_SUPABASE_SEARCH ? fetchResults('patient_name', `%${searchTerm}%`) : fetchResultsV2(searchTerm);
		}
	}, [searchTerm, fetchResults, disabled]);

	const updateSearchTerm = (input: string) => {
		setSearchTerm(input);
	};

	/**
	 * Handles the click event for an appointment.
	 * 
	 * @param {PatientRecord} appointment - The selected appointment record.
	 * 
	 * If the component is disabled or the appointment is not provided, the function will return early.
	 * If an `onSelect` callback is provided, it will be called with the selected appointment.
	 * Otherwise, it will navigate to the appointment details page using the appointment ID.
	 * After handling the selection, it resets the search term, fetched data, and selected appointment.
	 */
	const handleClick = (appointment: PatientRecord) => {
		if (disabled || !appointment) return

		if (onSelect) {
			onSelect(appointment);
		} else {
			navigate(`/appointments/${appointment.id}`, { state: { appointmentId: appointment.id } });
		}
		setSearchTerm("");
		setFetchedData([]);
		setSelectedAppointment(null);
	}

	/**
	 * Handles the key down event for the search input field.
	 * 
	 * @param e - The keyboard event triggered by the user.
	 * 
	 * If the 'Enter' key is pressed, the default action is prevented.
	 * If a selected appointment exists, it triggers the handleClick function with the selected appointment.
	 * If no selected appointment exists but there is fetched data, it triggers the handleClick function with the first item in the fetched data.
	 */
	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			if (selectedAppointment) {
				handleClick(selectedAppointment);
				return;
			}

			if (fetchedData.length > 0) {
				handleClick(fetchedData[0]);
				return;
			}
		}
	};

	const renderLoading = useMemo(() => isLoading && <div className="px-4 py-[6px] text-gray-900">Loading...</div>, [isLoading]);

	const renderNoMatches = useMemo(() =>
		searchTerm && fetchedData.length === 0 && <div className="px-4 py-[6px] text-gray-900">No matches</div>, [searchTerm, fetchedData]);

	const renderOptions = useMemo(() =>
		searchTerm &&
		fetchedData.length > 0 &&
		fetchedData.map((appointment, idx) => (
			<ComboboxOption key={idx} value={appointment} as={React.Fragment}>
				{({ focus }) => {
					const formattedDate = moment(appointment?.scheduled_at).format("MM/DD/YY");
					const formattedTime = moment(appointment?.scheduled_at).format("h:mm A");
					return (
						<div
							className={`w-full flex flex-col gap-2 z-50 cursor-default select-none px-4 py-[6px] ${focus ? "bg-max-50" : "text-gray-900"}`}
							onMouseEnter={() => setSelectedAppointment(appointment)}
						>
							<div className='flex justify-between items-center gap-2 text-gray-900 font-medium  text-md  '>
								<div className="">{`${appointment?.patient_name ? appointment?.patient_name : '-'}`}</div>
								<div className={`flex gap-1`}>
									{appointment?.patient_id && <span>{`ID: ${appointment?.patient_id}`}</span>}
								</div>
							</div>
							<div className='flex justify-between items-center gap-2 text-gray-900 font-normal  text-xs  '>
								{appointment?.contacts?.first_name && appointment?.contacts?.last_name && <span>{`${appointment?.contacts?.first_name} ${appointment?.contacts?.last_name}`}</span>}
								<span>{`${formattedDate}, ${formattedTime}`}</span>
							</div>

						</div>
					);
				}}
			</ComboboxOption >
		)), [searchTerm, fetchedData]);

	/**
	 * Renders the appropriate combobox options based on the current state.
	 *
	 * @returns {JSX.Element} The JSX element to be rendered.
	 *
	 * - If `isLoading` is true, it returns the loading indicator.
	 * - If `fetchedData` is an empty array, it returns the no matches indicator.
	 * - If `fetchedData` has elements, it returns the options.
	 */
	const renderComboboxOptions = () => {

		if (isLoading) {
			return renderLoading
		}

		if (fetchedData.length === 0) {
			return renderNoMatches
		}

		if (fetchedData.length > 0) {
			return renderOptions
		}
	}

	return (
		<div
			className={classNames(
				"w-full grid w-full gap-2",
				verticalLayout ? "grid-cols-1" : "grid-cols-1",
				disabled ? "opacity-50 cursor-not-allowed" : "hover:cursor-pointer",
				"z-50 w-full",
			)}
		>

			{/* Search Icon Button for Small Screens */}
			{!isSearchBarVisible && (
				<div className="block md:hidden">
					<button
						onClick={toggleSearchBar}
						className="p-2 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-max-700 ring-1 ring-inset rounded-md ring-gray-300"
					>
						<SearchIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
					</button>
				</div>
			)}

			{/* Search Bar for Small Screens */}
			{isSearchBarVisible && (
				<div className="relative flex items-center w-full md:hidden">
					<Combobox as="div" value={selectedAppointment} onChange={handleClick}>
						<form className="relative flex flex-1" onSubmit={(e) => e.preventDefault()}>
							<button
								type="button"
								onClick={toggleSearchBar}
								className="absolute inset-y-0 right-2 flex items-center rounded-r-md focus:outline-none"
							>
								<XIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
							</button>
							<ComboboxInput
								type="text"
								name="search"
								id="patient-search-input"
								value={searchTerm}
								placeholder="Search by Patient"
								onChange={(e) => updateSearchTerm(e.target.value)}
								onKeyDown={handleKeyDown}
								className="block w-[250px] sm:w-[280px] rounded-md border-0 py-[6px] pl-8 pr-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-max-700 sm:text-sm sm:leading-6"
								autoComplete="off"
							/>
							<SearchIcon className="absolute inset-y-0 left-2 top-2 h-5 w-5 text-gray-500" aria-hidden="true" />
							{searchTerm && (
								<ComboboxOptions className="absolute z-50 mt-1 top-full w-full overflow-auto rounded-md bg-white py-[6px] text-base shadow-lg ring-1 ring-gray-900 ring-opacity-5 focus:outline-none sm:text-sm">
									{renderComboboxOptions()}
								</ComboboxOptions>
							)}
						</form>
					</Combobox>
				</div>
			)}

			{/* Search Bar for Large Screens */}
			<div className="relative flex items-center w-full hidden md:flex">
				<SearchIcon className="absolute inset-y-0 left-2 h-5 w-5 text-gray-500" aria-hidden="true" />
				<Combobox as="div" value={selectedAppointment} onChange={handleClick}>
					<form className="relative flex flex-1" onSubmit={(e) => e.preventDefault()}>
						<ComboboxInput
							type="text"
							name="search"
							id="patient-search-input"
							value={searchTerm}
							placeholder="Search by Patient"
							onChange={(e) => updateSearchTerm(e.target.value)}
							onKeyDown={handleKeyDown}
							className="block w-[250px] sm:w-[280px] rounded-md border-0 py-[6px] pl-8 pr-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-max-700 sm:text-sm sm:leading-6"
							autoComplete="off"
						/>
						<SearchIcon className="absolute inset-y-0 left-2 top-2 h-5 w-5 text-gray-500" aria-hidden="true" />
						{searchTerm && (
							<ComboboxOptions className="absolute z-50 mt-1 top-full w-full overflow-auto rounded-md bg-white py-[6px] text-base shadow-lg ring-1 ring-gray-900 ring-opacity-5 focus:outline-none sm:text-sm">
								{renderComboboxOptions()}
							</ComboboxOptions>
						)}
					</form>
				</Combobox>
			</div>
		</div>
	);
};


export default SearchBar;
