import { uploadAndTranscribeBulkAudio } from "../lib/supabaseClient";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import { IDBPDatabase, openDB, DBSchema } from "idb";

export interface TranscriptSectionIndexDB {
	// Combination of transcriptId and transcriptSectionId
	audioData: Blob;
	fileName: string;
	createdAt: Date;
	bytesUploaded: number;
	uploadUrl: string | null;
	transcriptId: string;
	transcriptSectionId: string;
	patientRecordId: string;
	patientName: string;
	isUploading: boolean;
	retryCount: number;
}

interface HappyDB extends DBSchema {
	transcriptSections: {
		key: string;
		value: TranscriptSectionIndexDB;
		indexes: { createdAtIndex: Date };
	};
}

/*
	Note: we could manage global isRecording and isUploading and block retry if true,
	but I don't think we need to since we are only auto-retrying on startup (refresh), and
	can assume there is no recording or uploading happening at that time.
*/

export const INDEX_DB_VERSION = 6;
const RETRY_LIMIT = 3;

class AudioDb {
	private static instance: AudioDb;
	private db: IDBPDatabase<HappyDB> | null
	private dbName: string;

	private constructor() {
		this.db = null;
		this.dbName = "AudioDatabase";
	}

	// singleton pattern with lazy initialization
	static getInstance(): AudioDb {
		if (!AudioDb.instance) {
			AudioDb.instance = new AudioDb();
		}
		return AudioDb.instance;
	}

	// initialize the database connection
	async init(): Promise<void> {
		try {
			if (this.db) return; // avoid re-initialization

			this.db = await openDB<HappyDB>(this.dbName, INDEX_DB_VERSION, {
				// on upgrade, delete old store and create new one
				upgrade(db, oldVersion) {
					if (oldVersion < INDEX_DB_VERSION) {
						if (db.objectStoreNames.contains("transcriptSections")) {
							db.deleteObjectStore("transcriptSections");
						}
					}

					if (!db.objectStoreNames.contains("transcriptSections")) {
						const store = db.createObjectStore("transcriptSections", { keyPath: "key" });
						store.createIndex("createdAtIndex", "createdAt", { unique: false });
					}
				},
				// on blocked, log an error
				blocked(currentVersion, blockedVersion, event) {
					Sentry.captureException(new Error(`IDB blocked: ${currentVersion} -> ${blockedVersion}`), { extra: { context: 'init', event } })
				},
				blocking(currentVersion, blockingVersion, event) {
					Sentry.captureException(new Error(`IDB blocking: ${currentVersion} -> ${blockingVersion}`), { extra: { context: 'init', event } })
				},
				// on terminated, log an error and set the db to null
				terminated: () => {
					Sentry.captureException(new Error("IDB terminated"), { extra: { context: 'init' } });

					// not sure if this scoping will work
					this.db = null;
				}
			});

			// run post-initialization tasks
			await this.cleanupOldEntries();
			await this.setAllUploadsNotUploading();

			const audioBlobTest = await this.testAudioBlobUrlCompatibility();
			if (!audioBlobTest) {
				toast.error(
					"Uh oh! This browser may not support saving audio files and may degrade performance. Please ensure you are not in Private Browsing or Incognito mode. See Help Center or reach out to support for more help.",
					{ autoClose: false }
				);
			}
		} catch (error) {
			Sentry.captureException(error, { extra: { context: "init" } });
		}
	}

	/**
	 * Ensures the database is initialized before any operation
	 * !important: await this function before any db calls
	 */
	private async ensureDbInitialized(): Promise<void> {
		if (!this.db) {
			await this.init();
		}

		// throw an err if that failed :(
		if (!this.db) {
			throw new Error("Database not initialized");
		}
	}

	/**
	 * Deletes all entries in the transcriptSections store older than 5 days
	 * (3 days on iOS due to smaller storage limits)
	 */
	private async cleanupOldEntries(): Promise<void> {
		try {
			await this.ensureDbInitialized();

			// entries older than 5 days
			const deleteTimeFrame = new Date(new Date().setDate(new Date().getDate() - 5));

			if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
				deleteTimeFrame.setDate(deleteTimeFrame.getDate() - 3); // iOS: 3 days
			}

			const tx = this.db!.transaction("transcriptSections", "readwrite");
			const store = tx.objectStore("transcriptSections");
			let cursor = await store.openCursor();

			while (cursor) {
				if (new Date(cursor.value.createdAt) < deleteTimeFrame) {
					await cursor.delete();
				}

				cursor = await cursor.continue();
			}

			await tx.done;
		} catch (error) {
			Sentry.captureException(error, { extra: { context: "cleanupOldEntries" } });
		}
	}

	/**
	 * Set all uploads to not uploading
	 */
	private async setAllUploadsNotUploading(): Promise<void> {
		try {
			await this.ensureDbInitialized();

			const tx = this.db!.transaction("transcriptSections", "readwrite");
			const store = tx.objectStore("transcriptSections");
			let cursor = await store.openCursor();

			while (cursor) {
				const record = cursor.value;
				record.isUploading = false;
				await cursor.update(record);
				cursor = await cursor.continue();
			}

			await tx.done;
		} catch (error) {
			Sentry.captureException(error, { extra: { context: "setAllUploadsNotUploading" } });
		}
	}

	/**
	 * Test if the browser supports saving audio files by saving a test audio blob
	 * @returns true if the browser supports saving audio files
	 */
	private async testAudioBlobUrlCompatibility(): Promise<boolean> {
		try {
			await this.ensureDbInitialized();

			const testKey = "audioBlobTest/HappyDocTest";
			const audioBlob = new Blob(["test"], { type: "audio/wav" });
			const tx = this.db!.transaction("transcriptSections", "readwrite");
			const store = tx.objectStore("transcriptSections");

			await store.put({ key: testKey, audioData: audioBlob } as any);
			await store.delete(testKey);

			await tx.done;

			return true;
		} catch (error) {
			Sentry.captureException(error, { extra: { context: "testAudioBlobUrlCompatibility" } });
			return false;
		}
	}


	/**
	 * Adds a new audio blob to the database with the given details
	 * @param transcriptId 
	 * @param transcriptSectionId 
	 * @param blob 
	 * @param audioName 
	 * @param bytesUploaded 
	 * @param patientRecordId 
	 * @param patientName 
	 * @param doctorId 
	 * @returns true if the audio was added successfully
	 */
	async addTranscriptSectionAudio(
		transcriptId: string,
		transcriptSectionId: string,
		blob: Blob,
		audioName: string,
		bytesUploaded: number,
		patientRecordId: string,
		patientName: string = '',
		doctorId: string = ''
	): Promise<boolean> {
		try {
			await this.ensureDbInitialized();

			const key = `${transcriptId}/${transcriptSectionId}`;
			const data = {
				key,
				audioData: blob,
				fileName: audioName,
				createdAt: new Date(),
				transcriptId,
				transcriptSectionId,
				bytesUploaded,
				patientRecordId,
				uploadUrl: null,
				patientName,
				doctorId,
				isUploading: false,
				retryCount: 0,
			};

			await this.db!.put("transcriptSections", data);
			return true;
		} catch (error) {
			Sentry.captureException(error, { extra: { context: 'addTranscriptSectionAudio' } });
			return false;
		}
	}

	/**
	 * Updates the upload state of a transcript section
	 * @param transcriptId 
	 * @param transcriptSectionId 
	 * @param uploadUrl 
	 * @param bytesUploaded 
	 * @param isUploading 
	 * @param retryCount 
	 * @returns true if the transcript section was updated successfully
	 */
	async updateUploadState(
		transcriptId: string,
		transcriptSectionId: string,
		uploadUrl: string,
		bytesUploaded?: number,
		isUploading?: boolean,
		retryCount?: number
	): Promise<boolean> {
		try {
			await this.ensureDbInitialized();

			// get existing
			const key = `${transcriptId}/${transcriptSectionId}`;
			const data = await this.db!.get("transcriptSections", key);

			if (!data) throw new Error('Transcript section not found');

			// apply updates
			if (bytesUploaded !== undefined) data.bytesUploaded = bytesUploaded;
			if (isUploading !== undefined) data.isUploading = isUploading;
			if (retryCount !== undefined) data.retryCount = retryCount;
			data.uploadUrl = uploadUrl;

			// save
			await this.db!.put("transcriptSections", data);

			return true;
		} catch (error) {
			Sentry.captureException(error, { extra: { context: 'updateUploadState' } });
			return false;
		}
	}

	/**
	 * Get all incomplete uploads in the db
	 * @returns a list of incomplete records, if any
	 */
	async getIncompleteUploads(): Promise<TranscriptSectionIndexDB[]> {
		try {
			await this.ensureDbInitialized();

			const store = this.db!.transaction("transcriptSections").store;
			const allRecords = await store.getAll();
			return allRecords.filter(record => record.bytesUploaded < record.audioData.size);
		} catch (error) {
			Sentry.captureException(error, { extra: { context: 'getIncompleteUploads' } });
			return [];
		}
	}

	/**
	 * Get a given transcript section record from the db
	 * @param transcriptId 
	 * @param transcriptSectionId 
	 * @returns a specific audio blob from the db or undefined if not found
	 */
	async getTranscriptSectionAudio(
		transcriptId: string,
		transcriptSectionId: string
	): Promise<TranscriptSectionIndexDB | undefined> {
		try {
			await this.ensureDbInitialized();

			const key = `${transcriptId}/${transcriptSectionId}`;
			return await this.db!.get("transcriptSections", key);
		} catch (error) {
			Sentry.captureException(error, { extra: { context: 'getTranscriptSectionAudio' } });
			return undefined;
		}
	}

	/**
	 * Deletes a specific audio blob from the db
	 * @param transcriptId 
	 * @param transcriptSectionId 
	 * @returns true if the audio was deleted successfully
	 */
	async deleteTranscriptSectionAudio(transcriptId: string, transcriptSectionId: string): Promise<boolean> {
		try {
			await this.ensureDbInitialized();

			const key = `${transcriptId}/${transcriptSectionId}`;
			await this.db!.delete('transcriptSections', key);
			return true;
		} catch (error) {
			Sentry.captureException(error, { extra: { context: 'deleteTranscriptSectionAudio' } });
			return false;
		}
	}

	/**
	 * Deletes all audio blobs associated with a patient record
	 * @param patientRecordId 
	 * @returns true if the audio was deleted successfully
	 */
	async deleteAudioUsingPatientRecord(patientRecordId: string): Promise<boolean> {
		try {
			await this.ensureDbInitialized();

			const store = this.db!.transaction('transcriptSections', 'readwrite').store;
			let cursor = await store.openCursor();

			while (cursor) {
				if (cursor.value.patientRecordId === patientRecordId) {
					await cursor.delete();
				}

				cursor = await cursor.continue();
			}

			return true;
		} catch (error) {
			Sentry.captureException(error, { extra: { context: 'deleteAudioUsingPatientRecord' } });
			return false;
		}
	}

	/**
	 * Retries incomplete uploads
	 * This is the auto-retry, it is only called internally to this class on init
	 * @param isStartup 
	 * @returns new state of incomplete uploads, successful uploads are removed from the list
	 */
	async retryIncompleteUploads(isStartup: Boolean = false) {
		const incompleteUploads = await this.getIncompleteUploads();

		for (let incomplete of incompleteUploads) {
			try {
				if (incomplete.retryCount >= RETRY_LIMIT) {
					continue;
				}

				if (incomplete.isUploading) {
					continue;
				}

				if (+new Date() - +incomplete.createdAt < 5 * 60000 && !isStartup) {
					//don't upload if it was createdAt in the last 5 minutes && its not a startup retry
					continue;
				}

				// try traditional upload
				const { error } = await uploadAndTranscribeBulkAudio(
					incomplete.transcriptId,
					incomplete.transcriptSectionId,
					incomplete.audioData,
					incomplete.fileName,
					incomplete.patientRecordId,
					incomplete?.patientName || "",
					"",
					[],
				);
				if (error) {
					throw error;
				}

				// successful, get rid of the incomplete record
				await this.deleteTranscriptSectionAudio(incomplete.transcriptId, incomplete.transcriptSectionId);

			} catch (error: any) {
				// failed, update the retry count
				incomplete.retryCount = incomplete.retryCount + 1;
				await this.updateUploadState(
					incomplete.transcriptId,
					incomplete.transcriptSectionId,
					"",
					incomplete.bytesUploaded,
					false,
					incomplete.retryCount,
				);

				Sentry.captureException(error);
			}
		}
	}
}

export default AudioDb;
