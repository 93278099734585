// third-party libraries
import classNames from "classnames";
import { useEffect } from "react";
import { Combobox, ComboboxButton, ComboboxOptions, ComboboxOption } from "@headlessui/react";

// icons
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import CheckIcon from "@icons/check-circle.svg?react";

export type DashboardDoctor = {
    id: string;
    name: string;
};

type DoctorMultiSelectProps = {
    doctors: DashboardDoctor[];
    selectedDoctorIds: string[];
    setSelectedDoctorIds: (doctorIds: string[]) => void;
};

export const UNASSIGNED_DOCTOR_ID = "unassigned";

export const getLabelFromDoctorId = (doctorId: string, doctors: DashboardDoctor[]) => {
    if (doctorId === UNASSIGNED_DOCTOR_ID) {
        return "Unassigned";
    }

    const doctor = doctors?.find((doctor: DashboardDoctor) => doctor.id === doctorId);
    return doctor?.name || doctorId;
}

export const DoctorMultiSelect = (props: DoctorMultiSelectProps) => {
    const { doctors, selectedDoctorIds, setSelectedDoctorIds } = props;

    // if selectedDoctorIds is empty, select all doctors
    useEffect(() => {
        if (doctors.length > 0 && selectedDoctorIds.length === 0) {
            setSelectedDoctorIds(doctors.map((doc) => doc.id));
        }
    }, [doctors, selectedDoctorIds, setSelectedDoctorIds]);

    return (
        <div className="w-full min-w-[180px]">
            <Combobox
                as="div"
                value={selectedDoctorIds}
                onChange={(newDoctorIds: string[]) => setSelectedDoctorIds(newDoctorIds)}
                multiple
            >
                <div className="relative">
                    <div className="relative">
                        <ComboboxButton
                            className={classNames(
                                "w-full text-left rounded-md border-0 bg-white py-1.5 pl-3 pr-10 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-max-700 text-xs leading-tight sm:text-sm sm:leading-6",
                                selectedDoctorIds.length === 0 && "placeholder:text-gray-400"
                            )}
                        >
                            {selectedDoctorIds.length === doctors.length
                                ? "All Doctors"
                                : selectedDoctorIds.length === 1
                                    ? doctors?.find((doc) => doc.id === selectedDoctorIds[0])?.name || "1 doctor selected"
                                    : `${selectedDoctorIds.length} doctors selected`}
                        </ComboboxButton>
                        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                            <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </ComboboxButton>
                    </div>

                    {doctors.length > 0 && (
                        <ComboboxOptions className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-gray-900 ring-opacity-5 focus:outline-none sm:text-sm">
                            {doctors.map((doctor) => (
                                <ComboboxOption
                                    key={doctor.id}
                                    value={doctor.id}
                                    className={({ focus }) =>
                                        classNames(
                                            "relative cursor-default select-none py-2 pl-3 pr-9",
                                            focus ? "bg-max-700 text-white" : "text-gray-900"
                                        )
                                    }
                                >
                                    {({ focus, selected }) => (
                                        <>
                                            <span
                                                className={classNames(
                                                    "block truncate",
                                                    selected && "font-semibold"
                                                )}
                                            >
                                                {doctor.name}
                                            </span>
                                            {selected && (
                                                <span
                                                    className={classNames(
                                                        "absolute inset-y-0 right-0 flex items-center pr-4",
                                                        focus ? "text-white" : "text-max-700"
                                                    )}
                                                >
                                                    <CheckIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                            )}
                                        </>
                                    )}
                                </ComboboxOption>
                            ))}
                        </ComboboxOptions>
                    )}
                </div>
            </Combobox>
        </div>
    );
};
