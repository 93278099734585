import { ButtonGroup, Button } from "@mui/material";

export enum DateRangeOptions {
    Days7 = 'Past 7 days',
    Days30 = '30 days',
    Days60 = '60 days',
}

type DateRangeSelectProps = {
    selectedDateRangeButtonValue: DateRangeOptions,
    setSelectedDateRangeButtonValue: (value: DateRangeOptions) => void
}

export const getStartRangeTimestamp = (dateRange: DateRangeOptions): string => {
    const now = new Date(); // current date and time

    switch (dateRange) {
        case DateRangeOptions.Days60:
            now.setDate(now.getDate() - 60 + 1); // back 60 days, but include current day
            break;
        case DateRangeOptions.Days30:
            now.setDate(now.getDate() - 30 + 1); // back 30 days, but include current day
            break;
        case DateRangeOptions.Days7:
        default:
            now.setDate(now.getDate() - 7 + 1); // back 7 days, but include current day
            break;
    }

    // set time to midnight
    now.setHours(0, 0, 0, 0);

    return now.toISOString(); // return the timestamp in ISO 8601 format
};

export const getEndRangeTimestamp = (dateRange: DateRangeOptions): string => {

    const now = new Date(); // current date and time

    // round time down to nearest minute
    // this has the effect of a 'one minute cache period' before the data goes stale
    now.setSeconds(0); // round to the start of the current minute (set seconds to 0)
    now.setMilliseconds(0); // zero out milliseconds

    switch (dateRange) {
        case DateRangeOptions.Days60:
        case DateRangeOptions.Days30:
        case DateRangeOptions.Days7:
        default:
            return now.toISOString(); // return the timestamp in ISO 8601 format
    }
}

export const DateRangeSelect = (props: DateRangeSelectProps) => {

    const { selectedDateRangeButtonValue, setSelectedDateRangeButtonValue } = props;

    return (
        <div className="flex w-full items-center justify-center bg-gray-50 p-4">
            <ButtonGroup variant="outlined" aria-label="date-range-options">
                {Object.entries(DateRangeOptions).map(([key, label]) => (
                    <Button
                        className={selectedDateRangeButtonValue === label ? 'selected' : ''}
                        key={key}
                        onClick={() => setSelectedDateRangeButtonValue(label as DateRangeOptions)}
                    >
                        {label}
                    </Button>
                ))}
            </ButtonGroup>
        </div>
    );
};
