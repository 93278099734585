// third-party libraries
import * as Sentry from "@sentry/react";
import { useSession } from "@supabase/auth-helpers-react";

// react and hooks
import { createContext, useContext, ReactNode, useState, useEffect } from 'react';

// local components
import { DashboardDoctor } from '@features/dashboard/components/common/DoctorMultiSelect';
import { DateRangeOptions, getStartRangeTimestamp, getEndRangeTimestamp } from '@features/dashboard/components/common/DateRangeSelect';
import { getMetricKeyHydrator, MetricKeys } from '@features/dashboard/components/metrics/MetricComponent';


/*
* An interface for the context value of the DashboardStateContext
* This handles everything related to the state of the dashboard's content and selections 
* (but not metric data)
*/
interface DashboardStateContextValue {
    dashboardDoctors: DashboardDoctor[];
    setDashboardDoctors: (doctors: DashboardDoctor[]) => void;
    selectedDoctorIds: string[];
    setSelectedDoctorIds: (doctorIds: string[]) => void;
    selectedDateRangeButtonValue: DateRangeOptions;
    setSelectedDateRangeButtonValue: (value: DateRangeOptions) => void;
}

/*
* An interface for the context value of the DashboardMetricsContext
* This handles everything related to the metrics data and keys
*/
interface DashboardMetricsContextValue {
    metricKeys: MetricKeys[];
    metricData: any;

}

/*
* An interface for the value of the DashboardContext
*/
interface DashboardContextValue {
    dashboardStateContext: DashboardStateContextValue;
    dashboardMetricsContext: DashboardMetricsContextValue;
}

// create the context
const DashboardContext = createContext<DashboardContextValue | undefined>(undefined);

// create a hook to use the context
export function useDashboardContext(): DashboardContextValue {
    const context = useContext(DashboardContext);
    if (!context) {
        throw new Error("useDashboardContext must be used within a DashboardProvider");
    }
    return context;
}

/*
* The DashboardProvider is a context provider that wraps the entire dashboard page.
* It provides the context for the dashboard's state and metrics data.
*/
export function DashboardProvider({ children }: { children: ReactNode }) {
    // get the session - note that this may have to become injected if this dashboard is hosted outside of the main app
    const session = useSession();

    // DASHBOARD STATE
    const [dashboardDoctors, setDashboardDoctors] = useState<DashboardDoctor[]>([]);
    const [selectedDateRangeButtonValue, setSelectedDateRangeButtonValue] = useState<DateRangeOptions>(DateRangeOptions.Days7);
    const [selectedDoctorIds, setSelectedDoctorIds] = useState<string[]>([]);


    // DASHBOARD METRICS STATE
    // for now, set the metric keys to a hardcoded value
    const [metricKeys, setMetricKeys] = useState<MetricKeys[]>([
        MetricKeys.TimeSaved,
        MetricKeys.TimeSpentInVisits,
        MetricKeys.RecordsGenerated,
        MetricKeys.SentimentAnalysis,
        MetricKeys.VisitsSeen,
        MetricKeys.AverageClientTransaction
    ]);

    // instantiate the metric data with loading set to true and data set to undefined
    const [metricData, setMetricData] = useState<Record<string, { title: string, data: any, loading: boolean }>>(() => {
        const metricDataDict: Record<string, { title: string, data: any, loading: boolean }> = {};
        metricKeys.forEach((key) => {
            metricDataDict[key] = {
                title: key,
                data: undefined,
                loading: true,
            };
        });

        return metricDataDict;
    });

    // CONTEXT VALUES
    // set the dashboard state context value
    const dashboardStateContextValue: DashboardStateContextValue = {
        dashboardDoctors,
        setDashboardDoctors,
        selectedDoctorIds,
        setSelectedDoctorIds,
        selectedDateRangeButtonValue,
        setSelectedDateRangeButtonValue,
    }

    // set the metric keys and data in the provider
    const dashboardMetricsContextValue: DashboardMetricsContextValue = {
        metricKeys: metricKeys,
        metricData: metricData,
    }

    // DATA FETCHING
    // collect necessary metric retrieval functions
    const dataRetrievals = metricKeys.map((key) => {
        return {
            name: key,
            func: getMetricKeyHydrator(key)
        }
    });

    // hydrate the metric data by calling the hydrator functions and settings loading to false
    useEffect(() => {
        // if there are no data retrievals, return early
        if (!dataRetrievals || !selectedDateRangeButtonValue) {
            return;
        }

        // flag to prevent memory leaks
        let isMounted = true;

        // set all metrics to loading state
        setMetricData((prev: any) => {
            const newMetrics = { ...prev };
            dataRetrievals.forEach((retrieval) => {
                newMetrics[retrieval.name] = {
                    ...(prev[retrieval.name] || {}),
                    loading: true,
                };
            });
            return newMetrics;
        });

        // fetch data for each metric
        dataRetrievals.forEach((retrieval) => {
            const fetchData = async () => {
                const requestKey = getDataKey(
                    dashboardDoctors,
                    getStartRangeTimestamp(selectedDateRangeButtonValue),
                    getEndRangeTimestamp(selectedDateRangeButtonValue)
                );

                // skip if data already exists for this requestKey
                if (metricData[retrieval.name]?.data?.[requestKey]) {
                    return;
                }

                try {
                    // fetch the data
                    const data = await retrieval.func(
                        session?.access_token || "",
                        dashboardDoctors,
                        getStartRangeTimestamp(selectedDateRangeButtonValue),
                        getEndRangeTimestamp(selectedDateRangeButtonValue)
                    );

                    // update state incrementally as data resolves
                    if (isMounted) {
                        setMetricData((prev: any) => ({
                            ...prev,
                            [retrieval.name]: {
                                title: retrieval.name,
                                data: {
                                    ...(prev[retrieval.name]?.data || {}),
                                    [requestKey]: data,
                                },
                                loading: false,
                            },
                        }));
                    }
                } catch (error) {
                    Sentry.captureException(`Error fetching data for ${retrieval.name}: ${error}`);
                }
            };

            // fetch the data
            fetchData();
        });

        // cleanup function
        return () => {
            isMounted = false;
        };
    }, [dashboardDoctors, selectedDateRangeButtonValue, session]);

    // return the provider with the context values
    return (
        <DashboardContext.Provider value={{
            dashboardStateContext: dashboardStateContextValue,
            dashboardMetricsContext: dashboardMetricsContextValue,
        }}>
            {children}
        </DashboardContext.Provider>
    );
}

export const getDataKey = (dashboardDoctors: DashboardDoctor[], startRangeTimestamp: string, endRangeTimestamp: string) => `${dashboardDoctors.length}-${startRangeTimestamp}-${endRangeTimestamp}`;
