import { RecordingWidgetState, useAudioRecordingProvider } from '@/state/providers/RecordingProvider';
import classNames from 'classnames';
import WidgetDocumentSelect from './WidgetDocumentSelect';
import { useAppSelector } from '@/common/hooks/useRedux';

// internal icons
import FilePlusIcon from "@icons/file-plus-02.svg?react";
import MicrophoneIcon from "@icons/microphone-02.svg?react";
import Square from "@icons/square.svg?react";
import PlusCircleIcon from "@icons/plus-circle.svg?react";
import SaveIcon from "@icons/save-01.svg?react";
import SoundWaves1 from "@icons/recording-01.svg?react";
import SoundWaves2 from "@icons/recording-02.svg?react";
import { RecordingState } from '@/common/utils/types';
import Spinner from '@/common/components/Spinner';


interface Props {
    pulseScale: number;
    audioTime: string;
}

// view for when widget is in the "recording" state (during recording)
const RecordingView: React.FC<Props> = ({ pulseScale, audioTime }) => {
    const {
        // audio recording controls
        recorderControls: {
            togglePauseResume,
            recordingMessage,
            recordingState,
        },
        // recording widget controls
        recordingWidgetControls: {
            recordingWidgetState,
            setRecordingWidgetState,
            handleFinishRecording,
        },
        // patient data controls
        recordingPatientControls: {
            patientName,
        },
        // document select controls
        recordingDocumentSelectControls: {
            setGenerateOnStop,
        },
    } = useAudioRecordingProvider();

    const { selectedDocuments } = useAppSelector(state => state.documents);

    const isPaused = recordingWidgetState === RecordingWidgetState.PAUSED;
    const isLoading = recordingWidgetState === RecordingWidgetState.LOADING || recordingState === RecordingState.loading;
    /**
    * Save and Close
    * handler for the save and close button in the recording view
    */
    const handleSaveAndClose = async (e: React.MouseEvent<HTMLButtonElement>) => {

        // lock mouse events
        e.preventDefault();

        // set widget state back to uploading
        setRecordingWidgetState(RecordingWidgetState.UPLOADING);

        // set generate on stop to false
        setGenerateOnStop(false);

        // save recording
        handleFinishRecording();
    }

    /**
   * Generate Documents
   * handler for the generate button in the recording view
  */
    const handleGenerate = (e: React.MouseEvent<HTMLButtonElement>) => {

        // lock mouse events
        e.preventDefault();

        // set widget state back to uploading
        setRecordingWidgetState(RecordingWidgetState.UPLOADING);

        // set generate on stop to true
        setGenerateOnStop(true);

        // save recording
        handleFinishRecording();
    }

    /**
     * A function to get the proper dialog to display depending on what state the widget is in
     * and if the patient name is defined
     * 
     * @param isPaused: a boolean indicating whether the recording widget is in the paused state
     * @param patientName: the patient name provided in the widget when recording started
     */
    const getDialog = (isPaused: boolean, patientName: string | undefined) => {
        // check if the widget is in the paused state
        if (isPaused) {
            // if paused and patient name, display the patient name
            if (patientName) {
                return (
                    <>
                        <span className="text-white">Continue recording or generate selected documents for</span>
                        <span className="ml-1 text-max-600">{patientName}</span>
                    </>
                );
            } else {
                // otherwise, display paused dialog as is
                return (
                    <>
                        <span className="text-white">Continue recording or generate selected documents</span>
                    </>
                );
            }
        } else {
            // if not paused and patient name, display the patient name with recording dialog
            if (patientName) {
                return (
                    <>
                        <span className="text-white">Recording a Clip for<br /></span>
                        <span className="text-max-600">{patientName}</span>
                    </>
                );
            } else {
                // otherwise, display recording dialog as is
                return (
                    <>
                        <span className="text-white">Recording a Clip</span>
                    </>
                );
            }
        }
    };



    return (
        <div className="w-full h-full">
            <div className="w-full h-[129px] flex">
                {/* left column: microphone button */}
                <div className="w-[100px] flex items-center justify-center ml-4">
                    {isLoading ?
                        <div className="relative w-[60px] h-[60px] rounded-full flex items-center justify-center ">
                            <Spinner color='text-white' />
                        </div>
                        :
                        <button
                            id={isPaused ? "recording-widget-pause-button" : "recording-widget-stop-button"}
                            type="button"
                            className={classNames(
                                "relative w-[60px] h-[60px] rounded-full flex items-center justify-center text-xs font-semibold shadow-sm cursor-pointer transition-transform",
                                isPaused
                                    ? "bg-max-700 hover:bg-max-800 text-white group-hover:bg-max-800 group"
                                    : "bg-red-500 text-white hover:bg-red-600"
                            )}
                            style={{
                                boxShadow: isPaused
                                    ? "0 0 0 12px rgba(112, 128, 154, 0.50)"
                                    : `0 0 0 ${8 + (pulseScale > 0.25 ? 0.25 : pulseScale) * 6 * 8}px rgba(112, 128, 154, 0.50)`,
                            }}
                            onClick={(e) => {
                                e.preventDefault();
                                togglePauseResume();
                            }}
                        >
                            {isPaused ? (
                                <>
                                    <div>
                                        <MicrophoneIcon className="h-10 w-10 text-white" />
                                    </div>
                                    <div className="w-6 h-6 absolute top-0 right-0 text-white bg-max-700 rounded-full justify-center items-center inline-flex group-hover:bg-max-800">
                                        <PlusCircleIcon className="w-4 h-4" />
                                    </div>
                                </>
                            ) : (
                                <Square className="w-10 h-10 text-white" />
                            )}
                        </button>}
                </div>

                {/* right column: text and sound wave icons */}
                <div className="flex flex-col justify-between w-full px-6 my-4">
                    {/* dialog */}
                    <div className="text-sm font-medium leading-tight h-[60px]">
                        {getDialog(isPaused, patientName)}
                    </div>

                    {/* sound wave icons */}
                    <div className="mt-2 flex flex-col items-start">
                        <div className="bg-shadow-800 w-[144px] rounded-lg">
                            <div className="px-4 py-2 flex items-center text-white">
                                <div className="flex flex-row">
                                    <SoundWaves1 className="w-6 h-6" />
                                    <SoundWaves2 className="w-6 h-6" />
                                    <SoundWaves1 className="w-6 h-6" />
                                </div>
                                <div className="pl-2 text-center text-sm font-medium leading-tight">
                                    {audioTime}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* warning and error messages */}
            {recordingMessage.current && recordingWidgetState === RecordingWidgetState.RECORDING &&
                <div className={classNames("flex items-center justify-center py-2 px-2 text-xs text-start font-medium", recordingMessage.current.type === "error" ? "bg-[#F04438] text-[#FEE4E2]" : "bg-[#F79009] text-[#FEF0C7]",)}>
                    <span>{recordingMessage.current.message}</span>
                </div>
            }
            <div className="w-full justify-left">
                <WidgetDocumentSelect />
                <div>
                    <div className="w-full h-[76px] bg-shadow-800 rounded-b-lg flex flex-row items-center">
                        <button
                            className="w-full h-9 ml-6 mr-2.5 bg-white border-max-300 text-max-800 rounded-lg shadow border justify-center items-center inline-flex text-xs sm:text-sm font-semibold leading-tight"
                            onClick={handleSaveAndClose}
                        >
                            <SaveIcon className="w-4 h-4 sm:w-5 sm:h-5 relative mr-1" />
                            {isPaused ? (
                                <>
                                    Save & Close
                                </>
                            ) : (
                                <>
                                    Stop & Close
                                </>
                            )}
                        </button>
                        <button
                            disabled={selectedDocuments.length === 0}
                            className={classNames(
                                "w-full h-9 ml-2.5 mr-6 rounded-lg shadow border justify-center items-center inline-flex text-xs sm:text-sm font-semibold leading-tight",
                                selectedDocuments.length === 0
                                    ? "border-shadow-300 text-shadow-300 bg-shadow-700"
                                    : "border-max-700 text-white bg-max-700"
                            )}
                            onClick={handleGenerate}
                        >
                            <FilePlusIcon className="w-4 h-4 sm:w-4 sm:h-4 relative mr-1" />
                            {isPaused ? (
                                <>
                                    Generate
                                </>
                            ) : (
                                <>
                                    Stop & Generate
                                </>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RecordingView;