import zIndex from "@mui/material/styles/zIndex";

/**
 * Styles for the DataGrid component from MUI
 * @param {boolean} isSmallScreen - Whether the screen is small
 * @returns {CSSProperties} - The styles for the DataGrid
 */
export const getDataGridStyles = (isSmallScreen: boolean = false) => ({
    fontFamily: "Plus Jakarta Sans",
    // entire table styling border
    borderBottom: 1,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderRight: 0,
    borderLeft: 0,
    borderColor: "#E4E7EC", // gray-200
    '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
        outline: 'none',
    },
    // remove padding at beginning of column header container so that text is inline with column text
    '& .datagrid-table--header .MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
        width: 200,
    },
    // set header font styling
    '& .MuiDataGrid-columnHeaderTitle': {
        color: '#475467',
        fontWeight: 600,
        fontSize: 14,
    },
    // set header background color
    '& .datagrid-table--header': {
        backgroundColor: '#F9FAFB',
        color: "#475467",
        fontWeight: 600,
        fontSize: 14,
        paddingLeft: 2,
    },
    '& .datagrid-table-default-document-cell': {
        backgroundColor: '#F9FAFB',
        color: "#475467",
        fontWeight: 600,
        fontSize: 12,
        paddingLeft: 2,
    },
    // Remove header focus outline  
    "& .MuiDataGrid-columnHeader:focus,.MuiDataGrid-cell:focus": {
        outline: "none",
    },
    // All this to remove the focus outline on the cell when editing - sheesh
    "& .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within": {
        outline: 'none',
        outlineOffset: "10px",
    },
    '& .MuiDataGrid-cell--editing': {
        //outlineOffset: "10px",
        outline: '1px solid #D0D5DD',
        border: '1px solid #D0D5DD',
        boxShadow: 'none',
        backgroundColor: '#F9FAFB',
        '& .MuiInputBase-root': {
            outlineOffset: "10px",
            outline: '1px solid #26B5BB',
            border: 'none',
            boxShadow: 'none',
            "& .MuiInputBase-input:focus": {
                outline: '1px solid #26B5BB',
                border: 'none',
                boxShadow: 'none',
            },
            '&.Mui-focused': {
                outline: '1px solid #26B5BB',
                border: '1px solid #26B5BB',
                boxShadow: '0 0 0 1px #26B5BB',
                outlineOffset: "10px"
            },
        },
    },
    // selected row styling:
    '& .MuiDataGrid-row.Mui-selected': {
        backgroundColor: '#EAFAFB',
    },

    // selected hover row styling
    '& .MuiDataGrid-row.Mui-selected:hover': {
        backgroundColor: '#F9FAFB',
    },
    // editing row background color
    '& .MuiDataGrid-row--editing .MuiDataGrid-cell': {
        backgroundColor: '#F9FAFB',
    },
    // Row styling
    '& .datagrid-table--row': {
        backgroundColor: '#FFF',
    },
    '& .datagrid-table--row-default-document': {
        backgroundColor: '#F9FAFB',
    },
    // hover row styling
    '& .datagrid-table--row:hover': {
        backgroundColor: '#FCFCFD',
        cursor: 'pointer',
    },
    '& .datagrid-table--cell': {
        paddingLeft: 2,
    },
    // remove borders and separators
    '& .datagrid-table--column-cell': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingLeft: 2,
    },
});

export const toolTipStyles = { zIndex: 9999, fontSize: "12px", fontFamily: "Plus Jakarta Sans" }