import { getSupabaseDocumentTypes, toggleDocumentSupabase, getDocumentTemplates, getSharedDocIDs, addSelectedDocumentForDoctor, removeSelectedDocumentForDoctor, fetchHiddenDocumentsFromAccount } from "@common/lib/supabaseClient";
import { DocumentType } from "@common/utils/types";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";

/**
 * getLocalDocumentTypes is a helper function to get the selected documents from local storage.
 * It checks if the data is in the legacy format and converts it to the new format if needed.
 * @returns {string[]} - The array of selected document IDs.
 */
const getLocalDocumentTypes = () => {
	const docs = localStorage.getItem("selectedDocuments");
	try {
		if (docs) {
			const parsedDocs = JSON.parse(docs);

			// Check if the parsedDocs is an array of objects (legacy format)
			if (Array.isArray(parsedDocs) && parsedDocs.length > 0 && typeof parsedDocs[0] === 'object') {
				// Convert legacy format to new format (array of IDs)
				const docIds = parsedDocs.map((doc: { id: string }) => doc.id);
				// Store the converted data back to local storage
				localStorage.setItem("selectedDocuments", JSON.stringify(docIds));
				return docIds;
			}

			// If already in the new format, return as is
			return parsedDocs;
		}
		return [];
	} catch (e) {
		Sentry.captureException(e);
		return [];
	}
};

const initialState: {
	documentTypes: DocumentType[];
	documentTemplates: DocumentType[];
	ownedSharedDocumentIds: string[];
	selectedDocuments: string[];
	hiddenDocuments: string[],
	status: "idle" | "loading" | "succeeded" | "failed";
	error: string | null;
} = {
	documentTypes: [],
	documentTemplates: [],
	ownedSharedDocumentIds: [],
	hiddenDocuments: [],
	selectedDocuments: getLocalDocumentTypes() || [],
	status: "idle",
	error: null,
};

export const fetchDocumentTypes = createAsyncThunk(
	"document/fetchDocumentTypes",
	async ({ accountID, hiddenDocIDs, signal }: { accountID: string; hiddenDocIDs: string[], signal: AbortSignal }, { rejectWithValue }) => {
		try {
			const documentTypes = await getSupabaseDocumentTypes(signal, hiddenDocIDs, accountID);
			return documentTypes;
		} catch (error: any) {
			return rejectWithValue(error.response.data);
		}
	},
);

export const fetchDocumentTemplates = createAsyncThunk(
	"document/fetchDocumentTemplates",
	async ({ accountID, signal }: { accountID: string; signal: AbortSignal }, { rejectWithValue }) => {
		try {
			const documentTemplates = await getDocumentTemplates(signal, accountID);
			return documentTemplates;
		} catch (error: any) {
			return rejectWithValue(error.response.data);
		}
	},
);

export const fetchHiddenDocuments = createAsyncThunk(
	"document/fetchHiddenDocuments",
	async ({ signal }: { signal: AbortSignal }, { rejectWithValue }) => {

		try {
			const hiddenDocuments = await fetchHiddenDocumentsFromAccount(signal);
			return hiddenDocuments;
		} catch (error: any) {
			return rejectWithValue(error.response.data);
		}
	},
);

/**
 * fetchAllDocuments is an async thunk that fetches all documents for an account.
 * It fetches hidden documents, document templates, document types, and owned shared document IDs.
 * @param {string} accountID - The ID of the account to fetch documents for.
 * @param {AbortSignal} signal - The signal to cancel the fetch request.
 * @returns {Promise} - The promise containing the fetched data.
 */
export const fetchAllDocuments = createAsyncThunk(
	"document/fetchAllDocuments",
	async ({ accountID, signal }: { accountID: string; signal: AbortSignal }, { rejectWithValue, dispatch }) => {
		try {
			const hiddenDocuments = await dispatch(fetchHiddenDocuments({ signal }) as any).unwrap();
			const documentTemplates = await dispatch(fetchDocumentTemplates({ accountID, signal }) as any).unwrap();
			const documentTypes = await dispatch(fetchDocumentTypes({ accountID, hiddenDocIDs: hiddenDocuments, signal }) as any).unwrap();
			const ownedSharedDocumentIds = await dispatch(fetchOwnedSharedDocumentIds({ accountID, signal }) as any).unwrap();

			return { hiddenDocuments, documentTemplates, documentTypes, ownedSharedDocumentIds };
		} catch (error: any) {
			return rejectWithValue(error.response?.data || error.message);
		}
	}
);

/**
 * toggleDocumentInclude is an async thunk that toggles the include status of a document type.
 * It updates the document type in the database and the document types state.
 * @param {string} docTypeId - The ID of the document type to toggle.
 * @param {boolean} isActive - The new include status of the document type.
 * @returns {Promise} - The promise containing the fetched data.
 */
export const toggleDocumentInclude = createAsyncThunk(
	"document/toggleDocumentInclude",
	async ({ docTypeId, isActive }: { docTypeId: string; isActive: boolean }, { dispatch, rejectWithValue }) => {
		try {
			const error = await toggleDocumentSupabase(docTypeId, isActive);
			if (error) throw error;

			dispatch(documentIncludeToggled({ docTypeId, isActive }));

			// remove from selected documents
			if (!isActive) {
				await dispatch(removeDocumentForDoctor({ documentTypeId: docTypeId }));
			}

		} catch (error: any) {
			return rejectWithValue(error.response.data);
		}
	},
);

export const fetchOwnedSharedDocumentIds = createAsyncThunk(
	"document/fetchOwnedSharedDocumentIds",
	async ({ accountID, signal }: { accountID: string; signal: AbortSignal }, { rejectWithValue }) => {
		try {
			const ownedSharedDocumentIds = await getSharedDocIDs(signal, accountID);
			return ownedSharedDocumentIds;
		} catch (error: any) {
			return rejectWithValue(error.response?.data || error.message);
		}
	}
);

/**
 * addDocumentForDoctor is an async thunk that adds a document type for a doctor. 
 * It adds the document to the selected documents state and local storage. 
 * Also adds the document to the doctor's selected documents in the database.
 * @param {string} documentTypeId - The ID of the document to add.
 * @param {string} doctorId - The ID of the doctor to add the document to.
 */
export const addDocumentForDoctor = createAsyncThunk(
	"doctors/addDocumentForDoctor",
	async ({ documentTypeId, doctorId }: { documentTypeId: string; doctorId?: string }, { dispatch, rejectWithValue, getState }) => {
		try {
			const state = getState() as any;
			const selectedDoctor = state.doctors.selectedDoctor

			// add to selected documents state
			dispatch(addSelectedDocument(documentTypeId));

			if (doctorId || selectedDoctor) {
				// add to selected documents in the database
				const error = await addSelectedDocumentForDoctor(doctorId || selectedDoctor.id, documentTypeId);

				if (error) throw error;

				return
			}

		} catch (error: any) {
			return rejectWithValue(error.response.data);
		}
	},
);

/**
 * removeDocumentForDoctor is a thunk that removes a document from doctor's selected documents, selected document state and local storage.
 * @param {string} documentTypeId - The ID of the document to remove.
 * @param {string} doctorId - The ID of the doctor to remove the document from.
 */
export const removeDocumentForDoctor = createAsyncThunk(
	"doctors/removeDocumentForDoctor",
	async ({ documentTypeId, doctorId }: { documentTypeId: string; doctorId?: string }, { dispatch, rejectWithValue, getState }) => {
		try {
			// get the selected doctor from the state
			const state = getState() as any;
			const selectedDoctor = state.doctors.selectedDoctor;

			// remove from selected documents state
			dispatch(removeSelectedDocument(documentTypeId));

			// remove from the doctor's selected documents from the database
			if (doctorId || selectedDoctor) {
				// remove from selected documents in the database
				const error = await removeSelectedDocumentForDoctor(doctorId || selectedDoctor.id, documentTypeId);
				if (error) throw error;

				return
			}
		} catch (error: any) {
			return rejectWithValue(error.response.data);
		}
	},
);

const documentSlice = createSlice({
	name: "document",
	initialState,
	reducers: {
		documentTypeAdded(state, action) {
			const doc = state.documentTypes.find((doc) => doc.id === action.payload.id);
			if (!doc) state.documentTypes.push(action.payload);
		},
		documentTypeRemoved(state, action) {
			state.documentTypes = state.documentTypes.filter((doc) => doc.id !== action.payload);
		},
		documentIncludeToggled(state, action) {
			const { docTypeId, isActive } = action.payload;

			// update document types
			state.documentTypes = state.documentTypes.map((doc) =>
				doc.id === docTypeId ? { ...doc, is_active: isActive } : doc,
			);
		},
		sharedDocumentHiddenToggled(state, action) {
			const { docTypeId } = action.payload;

			// check if the document type ID is already in hiddenDocuments
			const isHidden = state.hiddenDocuments.includes(docTypeId);

			if (isHidden) {
				// if it's hidden, remove it from hiddenDocuments
				state.hiddenDocuments = state.hiddenDocuments.filter((doc) => doc !== docTypeId);
				state.documentTypes = state.documentTypes.map((doc) => doc.id === docTypeId ? { ...doc, is_active: true } : doc);
			} else {
				// otherwise, add it to hiddenDocuments
				state.hiddenDocuments.push(docTypeId);

			}
		},
		addSelectedDocument(state, action: { payload: string }) {
			// get document by id
			const doc = state.documentTypes.find((doc) => doc.id === action.payload);

			// check if the document is already in selectedDocuments
			const isAlreadySelected = state.selectedDocuments.includes(action.payload);

			if (doc && doc.id && !isAlreadySelected) {
				state.selectedDocuments.push(doc.id);
				localStorage.setItem("selectedDocuments", JSON.stringify(state.selectedDocuments));
			}
		},
		documentSelected(state, action: { payload: string[] }) {
			// get ids of selected documents and ensure they're unique and not null and are in documentTypes

			const selectedDocs = action.payload.filter((id) => {
				const doc = state.documentTypes.find((doc) => doc.id === id);
				return doc && doc.id;
			});

			state.selectedDocuments = selectedDocs;
			localStorage.setItem("selectedDocuments", JSON.stringify(selectedDocs));
		},
		removeSelectedDocument(state, action: { payload: string }) {
			state.selectedDocuments = state.selectedDocuments.filter((selectedId) => selectedId !== action.payload);
			localStorage.setItem("selectedDocuments", JSON.stringify(state.selectedDocuments));
		},
		clearDocumentState(state) {
			state.documentTypes = [];
			state.selectedDocuments = [];
			state.documentTemplates = [];
			state.hiddenDocuments = [];
			localStorage.removeItem("selectedDocuments");
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchDocumentTypes.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchDocumentTypes.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.documentTypes = action.payload;
			})
			.addCase(fetchDocumentTypes.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error?.message || null;
			})
			.addCase(fetchDocumentTemplates.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchDocumentTemplates.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.documentTemplates = action.payload; // Update the documentTemplates state with the fetched data
			})
			.addCase(fetchDocumentTemplates.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error?.message || null;
			})
			.addCase(fetchHiddenDocuments.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchHiddenDocuments.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.hiddenDocuments = action.payload || []; // Update the documentTemplates state with the fetched data
			})
			.addCase(fetchHiddenDocuments.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error?.message || null;
			})
			.addCase(fetchAllDocuments.fulfilled, (state, action) => {
				state.hiddenDocuments = action.payload.hiddenDocuments;
				state.documentTemplates = action.payload.documentTemplates;
				state.documentTypes = action.payload.documentTypes;

				if (state.documentTypes.length > 0) {
					// Filter out archived selected documents
					state.selectedDocuments = state.selectedDocuments.filter(selectedId =>
						state.documentTypes.some(type => type.id === selectedId)
					);

					// Filter out in active documents
					state.selectedDocuments = state.selectedDocuments.filter(selectedId =>
						state.documentTypes.some(type => type.id === selectedId && type.is_active)
					);
				}

				// Update local storage with new selectedDocuments
				localStorage.setItem("selectedDocuments", JSON.stringify(state.selectedDocuments));
			})
			.addCase(fetchOwnedSharedDocumentIds.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchOwnedSharedDocumentIds.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.ownedSharedDocumentIds = action.payload;
			})
			.addCase(fetchOwnedSharedDocumentIds.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error?.message || null;
			});
	},
});

export const {
	documentTypeAdded,
	documentIncludeToggled,
	sharedDocumentHiddenToggled,
	addSelectedDocument,
	documentSelected,
	documentTypeRemoved,
	removeSelectedDocument,
	clearDocumentState,
} = documentSlice.actions;

export default documentSlice.reducer;
